import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
interface QuesAnwRespo {
    id: string |number,
    type: string,
    attributes: {
        question: string,
        answer: string,
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    pageName: string;
    privacyDescription: string;
    isAnsOpen: boolean;
    selectedRole: number;
    queAnsData:QuesAnwRespo[],
    openQuestionId:number|null|string,
    istokenPresent:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PrivacyFaqController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getQuestionAnswerId: string = '';
    getPrivacyPolicyId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            pageName: '',
            privacyDescription: '',
            isAnsOpen: false,
            selectedRole: 2,
            queAnsData:[],
            openQuestionId:null,
            istokenPresent:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }

        // Customizable Area Start
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
            this.setState({ pageName: sessionData.pageName })
        }
        if (apiRequestCallId == this.getPrivacyPolicyId) {
            this.setState({ privacyDescription: responseJson?.data[0]?.attributes?.description })
        }
        if (apiRequestCallId == this.getQuestionAnswerId) {
           this.setState({queAnsData:responseJson.data})
        }

        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getPrivacyPlicy();
        this.getQuestionAnswer(1)
        const tokenPresent=await storage.get('loginToken')
        if(tokenPresent){
          this.setState({istokenPresent:true})
        }
    }
    handleBack = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'JobListing'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    }

    getPrivacyPlicy = () => {
        const header = {
            "Content-Type": 'application/json',
        };

        const setIntroMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPrivacyPolicyId = setIntroMsg.messageId;
        setIntroMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_terms_and_conditions/privacy_policies`
        );

        setIntroMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        setIntroMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    }

    getQuestionAnswer = (role: number) => {
        const header = {
            "Content-Type": 'application/json',
        };

        const setIntroMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getQuestionAnswerId = setIntroMsg.messageId;
        setIntroMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_settings/frequently_asked_questions?role_id=${role}`
        );

        setIntroMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        setIntroMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    }
    openAnswer = (questionId: number|string) => {
        this.setState({ openQuestionId: questionId });
    };

    closeAnswer = () => {
        this.setState({ openQuestionId: null });
    };
    selectRole = (role: number) => {
        this.setState({ selectedRole: role })
        this.getQuestionAnswer(role)
    }
    navigateSetting=()=>{
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'Settings2'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    }

    // Customizable Area End
}
