import React from "react";

// Customizable Area Start

import Footer from "../../../components/src/Footer.web";
import Navbar from "../../../components/src/Navbar.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { styled, Box, Typography } from "@material-ui/core";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <CustomNavbar>
          <Navbar navigation={this.props.navigation} id={""} NaviagationPage={""} />
        </CustomNavbar>
        <MobileNabar>
          <ArrowBackIosIcon data-test-id="navigateToHome" onClick={this.navigateToHome} style={{ color: "#fff" }} />
          <Typography style={webStyle.mobilenav}>Terms and Conditions</Typography>
        </MobileNabar>
        <MainBox>
          <Navigation>
            <Typography data-test-id="navigateToHome" onClick={this.navigateToHome} style={webStyle.home}>Home</Typography>
            {this.state.istokenPresent && <>
            <ArrowForwardIosIcon style={webStyle.forwardArrow} />
            <Typography style={webStyle.home} data-test-id="navigateSetting" onClick={this.navigateSetting}>Settings</Typography>
            </>}
            <ArrowForwardIosIcon style={webStyle.forwardArrow} />
            <Typography style={webStyle.joblisting}>Terms and conditions</Typography>
          </Navigation>
          <ContentBox>
            <Typography style={webStyle.heading}>Terms and conditions</Typography>
            <Typography style={webStyle.content}>
              Welcome to Palmetto Childcare Connect, a platform connecting substitute teachers with educational institutions. By accessing or using our website (the "Site"), you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read these Terms carefully before using our Site.
            </Typography>
            <Typography style={webStyle.suhHead}>
              1. Acceptance of Terms
            </Typography>
            <Typography style={webStyle.content}>
              By accessing or using Palmetto Childcare Connect, you agree to be bound by these Terms. If you do not agree with any of these Terms, you are prohibited from using or accessing this Site.
            </Typography>
            <Typography style={webStyle.suhHead}>2. Use of the Site</Typography>
            <Typography style={webStyle.content}>
              a. Eligibility: You must be at least 18 years old to use Palmetto Childcare Connect. By using the Site, you represent and warrant that you are at least 18 years old.<br />
              b. Account Registration: In order to access certain features of the Site, you may be required to register for an account. You agree to provide accurate and complete information when creating an account, and to update your information promptly if it changes.<br />
              c. User Conduct: You agree to use Palmetto Childcare Connect only for lawful purposes and in accordance with these Terms. You are solely responsible for your conduct and any content that you submit, post, or display on the Site.
            </Typography>
            <Typography style={webStyle.suhHead}>
              3. Job Postings
            </Typography>
            <Typography style={webStyle.content}>
              a. Accuracy of Information: Employers are responsible for the accuracy and completeness of the job postings they submit to Palmetto Childcare Connect. We do not guarantee the accuracy, completeness, or legality of any job posting.<br />
              b. Compliance with Laws: Employers agree to comply with all applicable laws and regulations in connection with their job postings, including but not limited to laws regarding equal employment opportunity and labor standards.
            </Typography>
            <Typography style={webStyle.suhHead}>
              4. User Content
            </Typography>
            <Typography style={webStyle.content}>
              a. License: By submitting, posting, or displaying content on Palmetto Childcare Connect, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content in connection with the Site.<br />
              b. Prohibited Content: You agree not to submit, post, or display any content on Palmetto Childcare Connect that is unlawful, defamatory, obscene, offensive, or otherwise objectionable.
            </Typography>
            <Typography style={webStyle.suhHead}>5. Intellectual Property</Typography>
            <Typography style={webStyle.content}>
              Palmetto Childcare Connect and its content, including but not limited to text, graphics, logos, and images, are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from the Site without our prior written permission.
            </Typography>
            <Typography style={webStyle.suhHead}>6. Disclaimer of Warranties</Typography>
            <Typography style={webStyle.content}>
              Palmetto Childcare Connect is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. We do not warrant that the Site will be uninterrupted or error-free, or that any defects will be corrected.
            </Typography>
            <Typography style={webStyle.suhHead}>7. Limitation of Liability</Typography>
            <Typography style={webStyle.content}>
              In no event shall Palmetto Childcare Connect be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site.
            </Typography>
            <Typography style={webStyle.suhHead}>
              8. Indemnification
            </Typography>
            <Typography style={webStyle.content}>
              You agree to indemnify and hold Palmetto Childcare Connect harmless from any claims, losses, liabilities, damages, costs, and expenses, including attorney's fees, arising out of or in connection with your use of the Site or your violation of these Terms.
            </Typography>
            <Typography style={webStyle.suhHead}>
              9. Governing Law
            </Typography>
            <Typography style={webStyle.content}>
              These Terms shall be governed by and construed in accordance with the laws of the State of [Insert State], without regard to its conflict of law principles.
            </Typography>
            <Typography style={webStyle.suhHead}>
              10. Changes to Terms
            </Typography>
            <Typography style={webStyle.content}>
              We reserve the right to modify or revise these Terms at any time. Your continued use of Palmetto Childcare Connect after any such changes constitutes your acceptance of the new Terms.
            </Typography>
            <Typography style={webStyle.content}>
              By using the Palmetto Childcare Connect Platform, you acknowledge that you have read and understood these Terms and agree to be bound by them.
            </Typography>
          </ContentBox>
        </MainBox>
        <CustomFooter>
          <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
        </CustomFooter>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 94,
  paddingBottom: 120,
  position: 'relative',
  "@media only screen and (max-width: 605px)": {
    paddingLeft: 20,
    paddingRight: 20,
  },
  "@media only screen and (max-width: 571px)": {
    paddingTop: 32,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 45
  }
})
const Navigation = styled(Box)({
  display: "flex",
  gap: "10px",
  position: "absolute" as "absolute",
  top: 32,
  left: 114,
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const CustomNavbar = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const MobileNabar = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 571px)": {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#395169',
    padding: 20,
    position: 'relative',
  }
})
const CustomFooter = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const ContentBox = styled(Box)({
  width: '826px',
  "@media only screen and (max-width: 1020px)": {
    width: 699
  },
  "@media only screen and (max-width: 820px)": {
    width: 529,
  },
  "@media only screen and (max-width: 605px)": {
    width: 'unset',
  }
})
const webStyle = {
  joblisting: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  forwardArrow: {
    color: "#475569",
    height: "15px",
    marginTop: 4
  },
  mobilenav: {
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center' as 'center',
    left: "13%",
    position: 'absolute' as 'absolute',
    right: '16%',
  },
  heading: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  content: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '25.6px',
    marginTop: '24px'
  },
  suhHead: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: "25.6px",
    marginTop: 32
  }
}

// Customizable Area End
