// Customizable Area Start
import React from "react";
import { Box, TextField, Typography, styled, Button, InputAdornment, Dialog, Divider, Avatar, Snackbar } from "@material-ui/core"

import PaymentsController, { Props } from "./PaymentsController";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web"
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import { admin, credit, education, experience, logo, school, location } from './assets'


export default class Payments extends PaymentsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <CustomNavbar>
                    <Navbar navigation={this.props.navigation} id={""} NaviagationPage={""} />
                </CustomNavbar>
                <CustomProfileHead>
                    <ArrowBackIos data-test-id='btnBack' onClick={this.navigateToHome} style={webStyle.backIcon} />
                    <NavbarTitle>
                        <img src={logo} />
                    </NavbarTitle>
                </CustomProfileHead>
                <MainBox>
                    <div>
                        {!this.state.creditPublish && <>
                            <Navigation>
                                <Typography data-test-id="navigateToHome" onClick={this.navigateToHome} style={webStyle.home}>Home</Typography>
                                <ArrowForwardIos style={webStyle.forwardArrow} />
                                <Typography style={webStyle.joblisting}>New job post</Typography>
                            </Navigation>
                            <ProfileHeading>
                                <PostHeading>Add credit</PostHeading>
                            </ProfileHeading>

                            <Typography style={webStyle.textheading}>Card  details</Typography>
                            <InputField
                                variant="outlined"
                                data-test-id="handleCardNumber"
                                fullWidth
                                placeholder="XXXX_XXXX_XXXX_XXXX"
                                value={this.state.cardNumber}
                                onChange={this.handleCardNumber}
                                inputProps={{
                                    maxLength: 19,
                                }}
                            />
                            <ErrorBox>{this.state.cardNumberError}</ErrorBox>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 24 }}>
                                <div style={{ flex: 1 }}>
                                    <Typography style={webStyle.textheading}>Valid </Typography>
                                    <InputField
                                        variant="outlined"
                                        fullWidth
                                        placeholder="--/--"
                                        value={this.state.valid}

                                        data-test-id="handleValidChange"
                                        onChange={this.handleValidChange}
                                        inputProps={{
                                            maxLength: 5,
                                        }}
                                    />
                                    <ErrorBox>{this.state.validError}</ErrorBox>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Typography style={webStyle.textheading}>CVV </Typography>
                                    <InputField
                                        variant="outlined"
                                        fullWidth
                                        placeholder="---"
                                        value={this.state.cvvnumber}
                                        data-test-id="handleCVVChange"
                                        onChange={this.handleCVVChange}
                                        inputProps={{
                                            maxLength: 3,
                                        }}
                                    />
                                    <ErrorBox>{this.state.cvvError}</ErrorBox>
                                </div>
                            </div>

                            <Typography style={webStyle.textheading}>Card Holder Name </Typography>
                            <InputField
                                variant="outlined"
                                fullWidth
                                placeholder="Enter card holder name"
                                value={this.state.cardHolderName}
                                data-test-id="handleCardHolderNameChange"
                                onChange={this.handleCardHolderNameChange}
                            />
                            <ErrorBox>{this.state.cardHolderNameError}</ErrorBox>

                            <Typography style={webStyle.textheading}>Credit Calculator</Typography>
                            <InputField variant="outlined" fullWidth value="1 Credit = $5" style={{ cursor: 'not-allowed', pointerEvents: 'none' }} />

                            <Typography style={webStyle.textheading}>Amount</Typography>
                            <InputField
                                variant="outlined"
                                fullWidth
                                placeholder="0.00"
                                value={this.state.dollarAmount}
                                data-test-id="handleAmountChange"
                                onChange={this.handleAmountChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><div style={{ color: '#1E293B' }}>$</div></InputAdornment>,
                                    endAdornment: <InputAdornment position="end">{this.state.creditAmount} Credit</InputAdornment>
                                }}
                            />
                            <ErrorBox>{this.state.dollarAmountError}</ErrorBox>

                            <Box style={webStyle.buttons}>
                                <Button onClick={this.navigateBack} style={webStyle.cancleBtn}>Cancel</Button>
                                <Box>
                                    <Button data-test-id="handlePreview" onClick={this.openPreview} style={webStyle.preview}>Preview</Button>
                                    <Button data-test-id="publishCredit" onClick={this.publishCredit} style={webStyle.continueBtn}>Publish</Button>
                                </Box>
                            </Box>
                            <CustomDialog open={this.state.openPreview} onClose={() => { this.setState({ openPreview: false }) }} data-test-id="closePreview">
                                <Box style={{ height: '708px' }}>
                                    <Box style={webStyle.header}> <Typography style={webStyle.headerHeading}>Job post preview</Typography> <Close onClick={() => { this.setState({ openPreview: false }) }} /> </Box>
                                    <Box style={webStyle.mainBox}>

                                        <JobDetailsTopBox>
                                            <Box style={webStyle.jobTitleMain} >
                                                <Box> <MainTitle>{this.state.jobTitle}</MainTitle><MainCompanyName>{this.state.organisation}</MainCompanyName></Box>
                                            </Box>
                                            <JobTitleTopMiddleSection>
                                                <JobTitleTopMiddleSmallBox> <Box style={webStyle.jobTitleMiddleInnerBox} > <img src={experience} alt="" /> <JobTitleTopCategoryTypo>EXPERIENCE</JobTitleTopCategoryTypo>  </Box><JobTitleTopCategoryDetail>{this.state.experience}</JobTitleTopCategoryDetail>
                                                </JobTitleTopMiddleSmallBox>
                                                <JobTitleTopMiddleSmallBox>
                                                    <Box style={webStyle.jobTitleMiddleInnerBox} ><img src={education} alt="" />  <JobTitleTopCategoryTypo>EDUCATION LEVEL</JobTitleTopCategoryTypo>
                                                    </Box>
                                                    <JobTitleTopCategoryDetail>{this.state.education}</JobTitleTopCategoryDetail>
                                                </JobTitleTopMiddleSmallBox>
                                                <JobTitleTopMiddleSmallBox>  <Box style={webStyle.jobTitleMiddleInnerBox} >
                                                    <img src={location} alt="" /> <JobTitleTopCategoryTypo>LOCATION</JobTitleTopCategoryTypo>
                                                </Box>
                                                    <JobTitleTopCategoryDetail>{this.state.city}, {this.state.state}, {this.state.country}</JobTitleTopCategoryDetail>
                                                </JobTitleTopMiddleSmallBox>
                                            </JobTitleTopMiddleSection>
                                            <JobDetailsBottomDivider />
                                            <Box style={webStyle.postedAtBox} > Posting Fee  <Typography style={webStyle.postedAtText} >30 Credits</Typography> </Box>

                                        </JobDetailsTopBox>

                                        <PostedByBox>
                                            <PostedByTextTypography>Posted by</PostedByTextTypography>
                                            <PostedByDetailsBox> <PostedByDetailsLeftBox>
                                                <ImageAvatar src={admin} alt="" /><PostedByDetailsMiddleBox>
                                                    <PostedByNameTypography>{this.state.firstname} {this.state.lastname}</PostedByNameTypography>
                                                    <PostedByNameDetailsBox>  <AdminTypeTypography>School Admin</AdminTypeTypography> <SchoolNameDot>.</SchoolNameDot> <SchoolNameTypography>{this.state.organisation}</SchoolNameTypography>
                                                    </PostedByNameDetailsBox>
                                                </PostedByDetailsMiddleBox>
                                            </PostedByDetailsLeftBox>

                                            </PostedByDetailsBox>
                                            <DividerForMobile />
                                        </PostedByBox>

                                        <JobDescriptionMainBox>
                                            <JobDescTitle>Job Description</JobDescTitle>
                                            <JobDescJobTitleBox> Job Title: <JobDescJobTitle>{this.state.jobTitle}</JobDescJobTitle>
                                            </JobDescJobTitleBox>
                                            <AboutOrg> <div dangerouslySetInnerHTML={{ __html: this.state.jobdescription }} /> </AboutOrg>
                                            <DividerForMobile />
                                        </JobDescriptionMainBox>

                                        <AboutOrganizationMainBox>
                                            <AboutOrganizationTitle>About Organization</AboutOrganizationTitle>
                                            <Box style={webStyle.aboutOrganizationTop} >
                                                <ImageAvatar src={school} alt="" />
                                                <AboutOrgDetailBox> <AboutOrgName>{this.state.organisation}</AboutOrgName>  <AboutOrgLocation>{this.state.city}, {this.state.state}, {this.state.country}</AboutOrgLocation>
                                                </AboutOrgDetailBox>
                                            </Box> <AboutOrg>{this.state.aboutOrg}</AboutOrg>
                                        </AboutOrganizationMainBox>

                                    </Box>
                                </Box>
                            </CustomDialog>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                open={this.state.notenough}
                                autoHideDuration={5000}
                                onClose={() => { this.setState({ notenough: false }) }}
                            >
                                <div style={webStyle.popup as React.CSSProperties}>
                                    Insufficient credits. Add more credits to create a post.
                                </div>
                            </Snackbar>
                        </>}
                    </div>

                    {this.state.creditPublish && <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', maxWidth: '580px' }}>
                        <div>
                            <img src={credit} />
                            <Typography style={webStyle.creditSuccess}>{this.state.creditAmount} Credit Successfully added in your Profile and {this.state.jobTitle} job post successfully created</Typography>
                            <Button style={{ ...webStyle.cancleBtn, marginTop: '80px' }} data-test-id="navigateBack" onClick={this.navigateBack}>Cancel</Button>
                        </div>
                    </div>}
                </MainBox>
                <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
            </>
        );
    }
}
const CustomDialog = styled(Dialog)({
    borderRadius: '16px',
    '& .MuiDialog-paperWidthSm': {
        minWidth: '700px',
        borderRadius: '16px',
        "@media only screen and (max-width: 816px)": {
            minWidth: '500px'
        },
        "@media only screen and (max-width: 553px)": {
            minWidth: '300px'
        },
    }
})
const JobDetailsTopBox = styled(Box)({
    backgroundColor: "#FFFFFF",
    'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
    margin: 'auto',
    marginTop: '32px',
    padding: '32px 32px 32px 32px',
    borderRadius: '16px',
    "@media only screen and (max-width: 816px)": {
        padding: '24px 24px 24px 24px',
    },
    "@media only screen and (max-width: 620px)": {
        padding: '16px 16px 16px 16px',
    },
    "@media only screen and (max-width: 414px)": {
        'box-shadow': 'none',
        borderRadius: '0',
        paddingTop: '0px',
    },
})
const DividerForMobile = styled(Divider)({
    display: 'none',
    "@media only screen and (max-width: 414px)": {
        display: 'block',
        margin: '16px 0 16px 0',
    },
});

const NavbarTitle = styled(Box)({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#FFFFFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  });

const MainCompanyName = styled(Typography)({
    marginTop: "8px",
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Inter',
    color: '#475569',
    "@media only screen and (max-width: 816px)": {
        fontSize: '14px',
        lineHeight: '22px',
    },
    "@media only screen and (max-width: 620px)": {
        lineHeight: '20px',
        fontSize: '14px',
        marginTop: "4",
    },
    "@media only screen and (max-width: 414px)": {
        display: 'none',
    },
});

const JobTitleTopMiddleSection = styled(Box)({
    alignItems: 'center',
    display: 'flex',
    marginTop: '32px',
    "@media only screen and (max-width: 816px)": {
        display: 'block',
    },
    "@media only screen and (max-width: 620px)": {
        display: 'block',
    },
    "@media only screen and (max-width: 414px)": {
        display: 'flex',
        flexDirection: 'column-reverse',
        margin: 'auto',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'center',
        marginTop: '16px',
    },
});

const MainTitle = styled(Typography)({
    lineHeight: '32px',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '24px',
    color: '#0F172A',
    "@media only screen and (max-width: 816px)": {
        lineHeight: '30px',
        fontSize: '20px',
    },
    "@media only screen and (max-width: 620px)": {
        lineHeight: '28px',
        fontSize: '18px',
    },
});

const JobTitleTopMiddleSmallBox = styled(Box)({
    marginRight: '48px',
    "@media only screen and (max-width: 816px)": {
        display: 'flex',
        marginBottom: '8px',
        alignItems: 'center',
    },
    "@media only screen and (max-width: 620px)": {
        width: '100%',
        marginBottom: '8px',
    },
    "@media only screen and (max-width: 414px)": {
        margin: 'auto',
        marginBottom: '8px',
    },
});


const JobTitleTopCategoryDetail = styled(Typography)({
    fontWeight: 400,
    marginTop: '8px',
    fontFamily: 'Inter',
    fontSize: '16px',
    width: '100%',
    lineHeight: '24px',
    color: '#334155',
    "@media only screen and (max-width: 816px)": {
        margin: '0 0 0 8px',
    },
    "@media only screen and (max-width: 620px)": {
        margin: '0 0 0 8px',
        fontSize: '14px',
    },
});

const JobDetailsBottomDivider = styled(Divider)({
    margin: '32px 0 32px 0',
    "@media only screen and (max-width: 414px)": {
        margin: '16px 0 16px 0',
    },
});

const PostedByBox = styled(Box)({
    backgroundColor: "#FFFFFF",
    padding: '32px 32px 32px 32px',
    'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
    margin: 'auto',
    borderRadius: '16px',
    marginTop: '24px',
    "@media only screen and (max-width: 816px)": {
        padding: '24px 24px 24px 24px',
    },
    "@media only screen and (max-width: 620px)": {
        padding: '16px 16px 16px 16px',
    },
    "@media only screen and (max-width: 414px)": {
        'box-shadow': 'none',
        paddingBottom: '0',
        borderRadius: '0',
        marginTop: '0px',
        paddingTop: '0',
    },
});


const JobTitleTopCategoryTypo = styled(Typography)({
    marginLeft: '8px',
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    color: '#64748B',
    lineHeight: '18px',
    "@media only screen and (max-width: 816px)": {
        width: '120px',
    },
    "@media only screen and (max-width: 414px)": {
        display: 'none',
    },
});

const PostedByTextTypography = styled(Typography)({
    fontFamily: 'Inter',
    marginBottom: '24px',
    fontSize: '20px',
    fontWeight: 600,
    color: '#1E293B',
    "@media only screen and (max-width: 816px)": {
        fontSize: '18px',
    },
    "@media only screen and (max-width: 620px)": {
        fontSize: '16px',
    },
    "@media only screen and (max-width: 414px)": {
        display: 'none',
    },
});

const PostedByDetailsBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "@media only screen and (max-width: 816px)": {
        display: 'block',
    },
    "@media only screen and (max-width: 620px)": {
        display: 'block',

    },
});


const ImageAvatar = styled(Avatar)({
    height: '56px',
    width: '56px',
});
const PostedByDetailsLeftBox = styled(Box)({
    alignItems: 'center',
    display: 'flex',
});

const PostedByDetailsMiddleBox = styled(Box)({
    marginLeft: '16px',
});

const PostedByNameTypography = styled(Typography)({
    color: '#1E293B',
    alignItems: 'center',
    fontFamily: 'Inter',
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'space-between',
    fontWeight: 500,
    lineHeight: '26px',
    "@media only screen and (max-width: 414px)": {
        fontSize: '14px',
    },
});

const PostedByNameDetailsBox = styled(Box)({
    marginTop: '4px',
    display: 'flex',
    "@media only screen and (max-width: 620px)": {
        display: 'block',
    },
    "@media only screen and (max-width: 414px)": {
        alignItems: "center",
        display: 'flex',
    },
});


const AdminTypeTypography = styled(Typography)({
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#64748B',
    "@media only screen and (max-width: 414px)": {
        fontSize: '12px',
    },
});

const SchoolNameTypography = styled(Typography)({
    marginLeft: '12px',
    fontWeight: 400,
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '22px',
    "@media only screen and (max-width: 620px)": {
        margin: '4px 0 0 0',
    },
    "@media only screen and (max-width: 414px)": {
        fontSize: '12px',
        margin: '0 0 0 6px',
    },
});
const JobDescriptionMainBox = styled(Box)({
    padding: '32px 32px 32px 32px',
    'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
    margin: 'auto',
    borderRadius: '16px',
    marginTop: '24px',
    backgroundColor: "#FFFFFF",
    "@media only screen and (max-width: 816px)": {
        padding: '24px 24px 24px 24px',
    },
    "@media only screen and (max-width: 620px)": {
        padding: '16px 16px 16px 16px',
    },
    "@media only screen and (max-width: 414px)": {
        'box-shadow': 'none',
        paddingTop: '0',
        borderRadius: '0',
    },
});


const SchoolNameDot = styled(Box)({
    lineHeight: '22px',
    marginLeft: '12px',
    color: '#64748B',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    "@media only screen and (max-width: 620px)": {
        display: 'none',
    },
    "@media only screen and (max-width: 414px)": {
        fontSize: '12px',
        display: 'block',
        marginLeft: '6px',
    },
})

const JobDescTitle = styled(Typography)({
    fontSize: '20px',
    color: '#1E293B',
    lineHeight: '28px',
    fontWeight: 600,
    fontFamily: 'Inter',
    "@media only screen and (max-width: 816px)": {
        lineHeight: '26px',
        fontSize: '18px',
    },
    "@media only screen and (max-width: 620px)": {
        lineHeight: '26px',
        fontSize: '16px',
    },
});

const JobDescJobTitleBox = styled(Box)({
    lineHeight: '25.6px',
    fontFamily: 'Inter',
    color: '#334155',
    fontSize: '16px',
    fontWeight: 600,
    "@media only screen and (max-width: 620px)": {
        lineHeight: '22px',
        fontSize: '14px',
    },
});

const JobDescJobTitle = styled(Typography)({
    fontFamily: 'Inter',
    fontSize: '16px',
    marginTop: '24px',
    display: 'inline-block',
    lineHeight: '25.6px',
    fontWeight: 400,
    color: '#334155',
    marginLeft: '4px',
    "@media only screen and (max-width: 620px)": {
        fontSize: '14px',
        marginTop: '12px',
        lineHeight: '22px',
    },
});

const AboutOrganizationMainBox = styled(Box)({
    backgroundColor: "#FFFFFF",
    'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
    margin: 'auto',
    marginTop: '24px',
    padding: '32px 32px 32px 32px',
    borderRadius: '16px',
    "@media only screen and (max-width: 816px)": {
        padding: '24px 24px 24px 24px',
    },
    "@media only screen and (max-width: 620px)": {
        padding: '16px 16px 16px 16px',
    },
    "@media only screen and (max-width: 414px)": {
        'box-shadow': 'none',
        paddingTop: "0px",
        borderRadius: '0',
        marginTop: '0px',
    },
});

const AboutOrganizationTitle = styled(Typography)({
    fontFamily: 'Inter',
    color: '#1E293B',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '28px',
    marginBottom: '24px',
    "@media only screen and (max-width: 620px)": {
        marginBottom: '20px',
        lineHeight: '24px',
        fontSize: '18px',
    },
    "@media only screen and (max-width: 414px)": {
        display: 'none',
    },
});

const AboutOrgDetailBox = styled(Box)({
    marginLeft: '16px',
});


const AboutOrg = styled(Typography)({
    fontSize: '16px',
    color: '#334155',
    lineHeight: '25.6px',
    fontFamily: 'Inter',
    fontWeight: 400,
    marginTop: '24px',
    "@media only screen and (max-width: 620px)": {
        fontSize: '14px',
        marginTop: '20px',
    },
});
const AboutOrgName = styled(Typography)({
    fontSize: '18px',
    fontFamily: 'Inter',
    lineHeight: '26px',
    fontWeight: 500,
    "@media only screen and (max-width: 620px)": {
        fontSize: '16px',
    },
});

const PostHeading = styled(Typography)({
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    "@media only screen and (max-width: 414px)": {
        fontSize: '16px'
    },
})

const AboutOrgLocation = styled(Typography)({
    marginTop: '4px',
    color: '#64748B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    fontFamily: 'Inter',
});
const CustomNavbar = styled(Box)({
    "@media only screen and (max-width: 657px)": {
        display: 'none'
    },
})
const CustomProfileHead = styled(Box)({
    display: 'none',
    "@media only screen and (max-width: 657px)": {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#395169',
        padding: 26,
        position: 'relative',
        paadingBottom: 37
    }
})
const MainBox = styled(Box)({
    background: "#F8F8F8",
    position: "relative",
    padding: "140px 426px 120px 426px",
    display: 'flex',
    justifyContent: 'center',
    "@media only screen and (max-width: 1330px)": {
        padding: 100,
    },
    "@media only screen and (max-width: 688px)": {
        paddingLeft: 29,
        paddingRight: 29
    },
    "@media only screen and (max-width: 571px)": {
        padding: '29px'
    },
    "@media only screen and (max-width: 404px)": {
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: '29px',
        paddingTop: '29px'
    },
    "@media only screen and (max-width: 375px)": {
        paddingLeft: 6,
        paddingRight: 6,
        paddingBottom: '29px',
        paddingTop: '29px'
    }
})
const Navigation = styled(Box)({
    display: "flex",
    gap: "10px",
    position: "absolute" as "absolute",
    top: 32,
    left: 114,
    "@media only screen and (max-width: 657px)": {
        left: 38
    },
    "@media only screen and (max-width: 571px)": {
        display: 'none',
    }
})
const ProfileHeading = styled(Box)({
    marginBottom: "32px",
    display: "flex",
    justifyContent: 'space-between',

})
const webStyle = {
    joblisting: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    home: {
        color: "#475569",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    forwardArrow: {
        color: "#395169",
        height: "15px",
        marginTop: 4
    },
    steps: {
        color: '#475569',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    textheading: {
        color: '#64748B',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        marginBottom: 6
    },
    buttons: {
        display: 'flex',
        justifyContent: "space-between",
        marginTop: '130px'
    },
    cancleBtn: {
        color: "#395169",
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'none' as 'none'
    },
    continueBtn: {
        borderRadius: '8px',
        background: '#395169',
        padding: '16px',
        color: '#fff',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'none' as 'none'
    },
    preview: {
        borderRadius: '8px',
        background: '#fff',
        border: '1px solid #395169',
        padding: '16px',
        color: '#0F172A',
        fontFamily: 'Inter',
        fontSize: '16px',
        marginRight: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'none' as 'none'
    },
    creditSuccess: {
        color: '#475569',
        fontFamily: 'Inter',
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '40px',
    },
    jobTitleMain: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    jobTitleMiddleInnerBox: {
        alignItems: 'center',
        display: 'flex',
    },
    postedAtBox: {
        fontFamily: 'Inter',
        lineHeight: '18px',
        fontSize: '16px',
        fontWeight: 600,
        color: '#000',
    },
    postedAtText: {
        color: '#64748B',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 600,
        display: 'inline-block',
        marginLeft: '4px',
        lineHeight: '18px',
    },
    aboutOrganizationTop: {
        display: 'flex',
    },
    headerHeading: {
        lineHeight: '32px',
        color: '#0F172A',
        fontWeight: 700,
        fontFamily: 'Inter',
        fontSize: '24px',
    },
    header: {
        background: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 16px',
    },
    mainBox: {
        background: '#F8F8F8',
        padding: '24px',
    },
    popup: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FEE2E2",
        color: "DC2626",
        borderRadius: "4px",
        height: "42px",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Inter",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderLeft: "4px solid #DC2626"
    },
    backIcon:{
        color:'#fff'
    }
}

export const InputField = styled(TextField)({
    marginBottom: "32px",

    "& .MuiOutlinedInput-input": {
        "&::placeholder": {
            fontWeight: 500,
            fontFamily: 'Inter',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'left',
        },
    },
    "& .MuiInputBase-input": {
        color: '#1E293B',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
    }
})
const ErrorBox = styled(Box)({
    color: '#DC2626',
    height: '18px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: "18px",
    marginTop: "-33px",
    marginBottom: "10px",
    "@media only screen and (max-width: 414px)": {
        marginTop: "-33px"
    },
})
// Customizable Area End
