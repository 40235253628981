import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import storage from '../../../framework/src/StorageProvider';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start

interface CustomFormState {
  [key: string]: any; // Allow any string key
  memberId: string;
  firstName: string;
  lastName: string;
  organization: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  phoneCode: string;
  phoneNumber: string;
  role: string;
  employee: string;
  program: string;
  capacity: string;
  aboutUs: string;
}

export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  memberId:string;
  street:string;
  zipCode:string;
  employee:string;
  program:string;
  role:string;
  capacity:string;
  aboutUs:string;
  aboutUsList:string[];
  countryList:string[];
  capacityList:string[];
  phoneCode:string;
  phoneCodeList:string[];
  options:string[];
  isSnackbarOpen:boolean;
  selectedRole: number;
  preferredModalityOptions: { id: number; label: string; checked: boolean; }[];
  preferredAgeGroupOptions: { id: number; label: string; checked: boolean; }[];
  preferredWorkingDaysOptions: { id: number; label: string; checked: boolean; }[];
  selectedModalityLabels: string[];
  selectedModalityIds: number[];
  selectedAgeGroupLabels: string[];
  selectedAgeGroupIds: number[];
  selectedWorkingDaysLabels: string[];
  selectedWorkingDaysIds: number[];
  preferredTimeShiftOptions: string[];
  preferredTimeShiftIds: number;
  preferredTimeShift: string;
  preferredNatureOfJobOptions: string[];
  preferredNatureOfJobIds: number;
  preferredNatureOfJob: string;
  travelDistance: string;
  documentSharingPreferenceOptions: string[];
  documentSharingPreferenceIds: number;
  documentSharingPreference: string;
  profileImage: File | null,
  uploadResume: File | null,
  uploadLetterOfInterest: File | null,
  uploadCredentials: File | null,
  uploadTranscripts: File | null,
  uploadNotesPSSA: File | null,
  uploadLetterReffEmail: File | null,
  errorDigit:string,
  memberIdError:string,
  firstNameError:string,
  lastNameError:string,
  organizationError:string,
  streetError:string,
  cityError:string,
  stateError:string,
  zipCodeError:string,
  phoneNumberError:string,
  employeeError:string,
  capacityError:string,
  

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "Select country",
      state: "",
      city: "",
      file: null,
      isChange: false,
      memberId:'',
      street:"",
      zipCode:"",
      employee:"",
      program:"Select program type",
      role:"Select role",
      capacity:"",
      aboutUs:"Select option",
      aboutUsList:["Online", "Word of Mouth", "Email"],
      countryList:["USA", "Canada", "Mexico"],
      capacityList:["1-10", "11-20", "21-30", "31-40", "41-50", "50+"],
      phoneCode:"+1",
      phoneCodeList:["+1","+91"],
      options:["option1", " option2","optoin3"],
      isSnackbarOpen:false,
      preferredModalityOptions: [
        { id: 1, label: 'Family Provider', checked: false },
        { id: 2, label: 'Group Provider', checked: false },
        { id: 3, label: 'Center Based Program', checked: false },
        { id: 4, label: 'School Age Program', checked: false },
      ],
      preferredAgeGroupOptions: [
        { id: 1, label: 'Infants', checked: false },
        { id: 2, label: 'Toddlers', checked: false },
        { id: 3, label: 'Preschool', checked: false },
        { id: 4, label: 'School Age', checked: false },
      ],
      preferredWorkingDaysOptions: [
        { id: 1, label: 'Monday', checked: false },
        { id: 2, label: 'Tuesday', checked: false },
        { id: 3, label: 'Wednesday', checked: false },
        { id: 4, label: 'Thursday', checked: false },
        { id: 5, label: 'Friday', checked: false },
        { id: 6, label: 'Saturday', checked: false },
        { id: 7, label: 'Sunday', checked: false },
      ],
      selectedModalityLabels: [],
      selectedModalityIds: [],
      selectedAgeGroupLabels: [],
      selectedAgeGroupIds: [],
      selectedWorkingDaysLabels: [],
      selectedWorkingDaysIds: [],
      preferredTimeShiftOptions: ['Morning', 'Afternoon', 'Any'],
      preferredTimeShiftIds: 0,
      preferredTimeShift: "",
      preferredNatureOfJobOptions: ['Full-time', 'Part-time', 'Any'],
      preferredNatureOfJobIds: 0,
      preferredNatureOfJob: "",
      travelDistance: "",
      documentSharingPreferenceOptions: ['Share with Palmetto', 'Share with Job Providers', 'Share with both'],
      documentSharingPreferenceIds: 0,
      documentSharingPreference: "",
      selectedRole: 2,
      profileImage: null,
      uploadResume: null,
      uploadLetterOfInterest: null,
      uploadCredentials: null,
      uploadTranscripts: null,
      uploadNotesPSSA: null,
      uploadLetterReffEmail: null,
      errorDigit:"",
      memberIdError:"",
      firstNameError:"",
      lastNameError:"",
      organizationError:"",
      streetError:"",
      cityError:"",
      stateError:"",
      zipCodeError:"",
      phoneNumberError:"",
      employeeError:"",
      capacityError:""


      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(responseJson.error){
          this.setState({isSnackbarOpen:true})
          return;
        }
        if (apiRequestCallId == this.editUserApiCallId) {
          
            const message: Message = new Message(getName(MessageEnum.NavigationMessage))
            message.addData(
              getName(MessageEnum.NavigationTargetMessage),
              'Verification'
            );
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
            const raiseMessage: Message = new Message(
              getName(MessageEnum.NavigationPayLoadMessage)
            );
            message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(message);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let userData = await getStorageData("userData");
    userData = JSON.parse(userData)
    if(userData){
      this.setState({userId: userData.data?.id})
    }
    const selectedrole= await storage.get("selectedRole")
    this.setState({selectedRole:selectedrole})
  }

  onValueChange = (name: string, value: string|unknown) => {
    let valuenumber= value as string; 
    let cleanedValue = valuenumber;
    let errorMessage = '';
    if (name === 'firstName' || name === "lastName"  || name === "organization" || name === "city"|| name === "state" ) {
      cleanedValue = valuenumber.replace(/\d/g, '');
      if (value !== cleanedValue) {
        errorMessage = 'Only characters are allowed.';
      }
    } else if (name === 'memberId' || name === "zipCode" || name === "phoneNumber" || name === "employee"  || name === "capacity" ) {
      cleanedValue = valuenumber.replace(/\D/g, ''); 
      if (value !== cleanedValue ) {
        errorMessage = 'Only numbers are allowed.';
      }
      if (name === 'phoneNumber' && cleanedValue.length > 10) {
        cleanedValue = cleanedValue.slice(0, 10); 
      }
    }
    this.setState({ ...this.state, [name]: cleanedValue, [`${name}Error`]: errorMessage });
  };

  validateForm() {
    const {
      memberId,
      firstName,
      lastName,
      organization,
      street,
      city,
      state,
      zipCode,
      country,
      phoneNumber,
      role,
      employee,
      program,
      capacity,
      aboutUs
    } = this.state;

    return (
      memberId != "" &&
      firstName != "" &&
      lastName != "" &&
      organization != "" &&
      street != "" &&
      city != "" &&
      state != "" &&
      zipCode != "" &&
      country != "Select country" &&
      phoneNumber != "" &&
      role != "Select role" &&
      employee != "" &&
      program != "Select program type" &&
      capacity != "" &&
      aboutUs != "Select option" 
    );
  }
  

  submitButton=()=>{
    const { employee, capacity } = this.state;

    if (!this.validateForm()) {
      this.setState({ isSnackbarOpen: true });
    } else {
      let hasError = false;
  
      if (parseInt(employee, 10) > 5000) {
        this.setState({ employeeError: 'Number of employees cannot exceed 5000' });
        hasError = true;
      } 
      if (parseInt(capacity, 10) > 50) {
        this.setState({ capacityError: 'Program capacity cannot exceed 50' });
        hasError = true;
      } 
  
      if (hasError) {
        return;
      }
      const header = {
        "Content-Type": configJSON.apiContentType,
      };
      const formData = new FormData();
        formData.append("data[attributes][first_name]", this.state.firstName);
        formData.append("data[attributes][last_name]", this.state.lastName);
        formData.append("data[attributes][member_id]", this.state.memberId);
        formData.append("data[attributes][organization]", this.state.organization);
        formData.append("data[attributes][street_address]", this.state.street);
        formData.append("data[attributes][city]", this.state.city);
        formData.append("data[attributes][state]", this.state.state);
        formData.append("data[attributes][zip_code]", this.state.zipCode);
        formData.append("data[attributes][country]", this.state.country);
        formData.append("data[attributes][role_id]", '1');
        formData.append("data[attributes][no_of_employees]", this.state.employee);
        formData.append("data[attributes][program_type]", this.state.program);
        formData.append("data[attributes][program_capacity]", this.state.capacity);
        formData.append("data[attributes][about_us]", this.state.aboutUs);
        formData.append("data[attributes][country_code]", this.state.phoneCode);
        formData.append("data[attributes][phone_number]", this.state.phoneNumber);
        formData.append("data[attributes][full_phone_number]", this.state.phoneCode+this.state.phoneNumber);
        formData.append("[data[type]", "email_account");
        storage.set("phone",`${this.state.phoneCode.replace('+' , '')}${this.state.phoneNumber}`)
        storage.set("filled",true)
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.editUserApiCallId = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/accounts/${this.state.userId}/update_account`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `PUT`
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);

        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'Verification'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);

        this.setState({firstName:'',lastName:'',memberId:'',role:'',country:'',city:'',state:'',street:'',zipCode:'',program:'',phoneNumber:'',capacity:'',aboutUs:'',employee:'',organization:''})
    }
  }

  closeSnackbar=()=>{ this.setState({isSnackbarOpen:false})}

  handleBackToRoleSelection = () => {
    const roleSelection: Message = new Message(getName(MessageEnum.NavigationMessage));
    roleSelection.addData(getName(MessageEnum.NavigationTargetMessage), "Rolesandpermissions2");
    roleSelection.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(roleSelection);
  }

  handleMultiCheckBoxChange = (index: number, listType: string) => {
    let newOptions = [];
    let selectedLabels = [];
    let selectedIds = [];
  
    switch (listType) {
      case 'preferredModality':
        newOptions = [...this.state.preferredModalityOptions];
        newOptions[index].checked = !newOptions[index].checked;
        selectedLabels = newOptions.filter(option => option.checked).map(option => option.label);
        selectedIds = newOptions.filter(option => option.checked).map(option => option.id);
        this.setState({ 
          preferredModalityOptions: newOptions,
          selectedModalityLabels: selectedLabels,
          selectedModalityIds: selectedIds
        });
        break;
  
      case 'preferredAgeGroup':
        newOptions = [...this.state.preferredAgeGroupOptions];
        newOptions[index].checked = !newOptions[index].checked;
        selectedLabels = newOptions.filter(option => option.checked).map(option => option.label);
        selectedIds = newOptions.filter(option => option.checked).map(option => option.id);
        this.setState({ 
          preferredAgeGroupOptions: newOptions,
          selectedAgeGroupLabels: selectedLabels,
          selectedAgeGroupIds: selectedIds
        });
        break;
  
      case 'preferredWorkingDays':
        newOptions = [...this.state.preferredWorkingDaysOptions];
        newOptions[index].checked = !newOptions[index].checked;
        selectedLabels = newOptions.filter(option => option.checked).map(option => option.label);
        selectedIds = newOptions.filter(option => option.checked).map(option => option.id);
        this.setState({ 
          preferredWorkingDaysOptions: newOptions,
          selectedWorkingDaysLabels: selectedLabels,
          selectedWorkingDaysIds: selectedIds
        });
        break;
  
      default:
        break;
    }
  };

  handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, listType: string) => {
    const selectedOption = (event.target as HTMLInputElement).value;
    let optionId;

    switch (listType) {
        case 'preferredTimeShift':
            optionId = this.state.preferredTimeShiftOptions.indexOf(selectedOption) + 1;
            this.setState({ preferredTimeShift: selectedOption, preferredTimeShiftIds: optionId });
            break;
        case 'preferredNatureOfJob':
            optionId = this.state.preferredNatureOfJobOptions.indexOf(selectedOption) + 1;
            this.setState({ preferredNatureOfJob: selectedOption, preferredNatureOfJobIds: optionId });
            break;
        case 'documentSharingPreference':
            optionId = this.state.documentSharingPreferenceOptions.indexOf(selectedOption) + 1;
            this.setState({ documentSharingPreference: selectedOption, documentSharingPreferenceIds: optionId });
            break;
        default:
            break;
    }
};


  handleDocumentChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    this.setState({ ...this.state, [name]: file });
  };

  setProfileImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ profileImage: event.target.files?.[0] || null });
  };

  checkIsFormValid = () => {
    const {
      firstName, lastName, phoneNumber, street, city, state, zipCode, country, selectedModalityIds, selectedAgeGroupIds, selectedWorkingDaysIds, preferredTimeShiftIds, preferredNatureOfJobIds, travelDistance, documentSharingPreferenceIds, profileImage, uploadResume, uploadLetterOfInterest, uploadCredentials, uploadTranscripts, uploadNotesPSSA, uploadLetterReffEmail, aboutUs,
    } = this.state;
    return (
        firstName !== '' &&
        lastName !== '' &&
        street !== '' &&
        city !== '' &&
        state !== '' &&
        country !== "Select country" &&
        selectedModalityIds.length !== 0 &&
        selectedAgeGroupIds.length !== 0 &&
        selectedWorkingDaysIds.length !== 0 &&
        travelDistance !== '' &&
        aboutUs !== "Select option" &&
        profileImage !== null &&
        preferredTimeShiftIds !== 0 &&
        preferredNatureOfJobIds !== 0 &&
        documentSharingPreferenceIds !== 0 &&
        uploadResume !== null &&
        uploadLetterOfInterest !== null &&
        uploadCredentials !== null &&
        uploadTranscripts !== null &&
        uploadNotesPSSA !== null &&
        uploadLetterReffEmail !== null &&
        phoneNumber !== '' &&
        zipCode !== '' 
      );
  }

  continueForm = () => {

    const header = {
    };

   
    storage.set("profilepic",this.state.profileImage)
    const formData = new FormData();

    formData.append("data[attributes][first_name]", this.state.firstName);
    formData.append("data[attributes][last_name]", this.state.lastName);
    formData.append("data[attributes][street_address]", this.state.street);
    formData.append("data[attributes][city]", this.state.city);
    formData.append("data[attributes][state]", this.state.state);
    formData.append("data[attributes][zip_code]", this.state.zipCode);
    formData.append("data[attributes][country]", this.state.country);
    formData.append("data[attributes][role_id]", '2');
    this.state.selectedModalityIds.forEach((id) => {
      formData.append(`data[attributes][preferred_modality_ids][]`, id.toString());
    });
    this.state.selectedAgeGroupIds.forEach((id) => {
      formData.append(`data[attributes][preferred_age_group_ids][]`, id.toString());
    });
    this.state.selectedWorkingDaysIds.forEach((id) => {
      formData.append(`data[attributes][preferred_working_days_ids][]`, id.toString());
    });
    if (this.state.profileImage) {
      console.log("Profile Image:", this.state.profileImage);
      if (this.state.profileImage instanceof File) {
        formData.append("data[attributes][profile_image]", this.state.profileImage);
      }
    }

    if (this.state.uploadResume && this.state.uploadResume instanceof File) {
      formData.append("data[attributes][upload_resumes][]", this.state.uploadResume);
    } 
    if (this.state.uploadLetterOfInterest && this.state.uploadLetterOfInterest instanceof File) {
      formData.append("data[attributes][letters_of_interests][]", this.state.uploadLetterOfInterest);
    } 
    if (this.state.uploadCredentials && this.state.uploadCredentials instanceof File) {
      formData.append("data[attributes][credentials][]", this.state.uploadCredentials);
    } 
    if (this.state.uploadTranscripts && this.state.uploadTranscripts instanceof File) {
      formData.append("data[attributes][transcripts][]", this.state.uploadTranscripts);
    } 
    if (this.state.uploadNotesPSSA && this.state.uploadNotesPSSA instanceof File) {
      formData.append("data[attributes][note_pages][]", this.state.uploadNotesPSSA);
    } 
    
    if (this.state.uploadLetterReffEmail && this.state.uploadLetterReffEmail instanceof File) {
      formData.append("data[attributes][letter_of_reference_emails][]", this.state.uploadLetterReffEmail);
    } 
    

    formData.append("data[attributes[preferred_time_shift_ids][]", this.state.preferredTimeShiftIds.toString());
    formData.append("data[attributes][preferred_nature_of_job_ids][]", this.state.preferredNatureOfJobIds.toString());
    formData.append("data[attributes][document_sharing_ids][]", this.state.documentSharingPreferenceIds.toString());
    formData.append("data[attributes][preferred_travel_distance]", this.state.travelDistance);
    formData.append("data[attributes][about_us]", this.state.aboutUs);
    formData.append("data[attributes][full_phone_number]", this.state.phoneCode+this.state.phoneNumber);
    formData.append("data[type]", "email_account");
    formData.append("data[attributes][country_code]", this.state.phoneCode);
    formData.append("data[attributes][phone_number]", this.state.phoneNumber);
    storage.set("phone",`${this.state.phoneCode.replace('+' , '')}${this.state.phoneNumber}`)

    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/update_account`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleback=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'Rolesandpermissions2' //screen name in app.tsx or app.js
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
  }
  // Customizable Area End
}
