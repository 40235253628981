import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import storage from '../../../framework/src/StorageProvider';
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConditions: {
    isCapital: boolean;
    isLowercase: boolean;
    isNumber: boolean;
    isLength: boolean;
  };
  checkedPrivacyAndTerms: boolean;
  showAlert: boolean;
  passwordErrorMsg: string;
  emailErrorMsg: string;
  alertMsg: string;

  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConditions: {
        isCapital: false,
        isLowercase: false,
        isNumber: false,
        isLength: false,
      },
      checkedPrivacyAndTerms: false,
      showAlert: false,
      passwordErrorMsg: '',
      emailErrorMsg: '',
      alertMsg: '',

      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/);
    this.emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
    switch (apiRequestCallId) {
      case this.validationApiCallId:
        this.arrayholder = responseJson.data;
    
        if (this.arrayholder && this.arrayholder.length !== 0) {
          let regexData = this.arrayholder[0];
    
          if (regexData.password_validation_regexp) {
            this.passwordReg = new RegExp(regexData.password_validation_regexp);
          }
    
          if (regexData.password_validation_rules) {
            this.setState({
              passwordHelperText: regexData.password_validation_rules
            });
          }
    
          if (regexData.email_validation_regexp) {
            this.emailReg = new RegExp(regexData.email_validation_regexp);
          }
        }
        break;
    
      case this.createAccountApiCallId:
        this.createAccountApiCallIdReceive(responseJson, errorReponse)
        break;
    
      default:
        break;
    }
    
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
      const email=await storage.get("typedEmail")
      const password=await storage.get("typedPassward")
      this.setState({email:email,password:password})
  }

  createAccountApiCallIdReceive = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      setStorageData("userData", JSON.stringify(responseJson))
      const navigateRolesAndPermission2 = new Message(getName(MessageEnum.NavigationMessage));
      navigateRolesAndPermission2.addData(getName(MessageEnum.NavigationTargetMessage), "Rolesandpermissions2");
      navigateRolesAndPermission2.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigateRolesAndPermission2);
    } else {
      // Check Error Response
      this.loginApiErrorMessage(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    storage.set("typedEmail",this.state.email)
    storage.set("typedPassward",this.state.password)
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
          message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'TermsConditions' 
          );
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
          const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
          );
          message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
          this.send(message);
  }

  navigateToPrivacyPolicy(pageName:string) {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PrivacyFaq'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), { ...this.props, pageName })
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { pageName });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  goToLoginPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  handleEmailChange = (text: string) => {
    this.setState({
      email: text,
      emailErrorMsg: text ? "" : configJSON.errorMsgPleaseEnterEmail,
    });
  };

  handlePasswordChange = (value: string) => {
    this.setState({ password: value, passwordErrorMsg: "", }, () => {
      const { password } = this.state;
      const passwordConditions = {
        isCapital: /[A-Z]/.test(password),
        isLowercase: /[a-z]/.test(password),
        isNumber: /\d/.test(password),
        isLength: password.length >= 8,
      };
      this.setState({ passwordConditions });
      if(!password){
        this.setState({
          passwordErrorMsg: `${configJSON.errorMsgPleaseEnterPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`,
        });
      }
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleCheckboxChange = (value: boolean) => {
    this.setState({
        checkedPrivacyAndTerms: value,
        showAlert: false,
    });
  }

  loginApiErrorMessage = (responseJson: any) => {
    let errorMessage = configJSON.somethingWentWrong

    if (responseJson?.errors) {
        errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors[0].failed_login || configJSON.somethingWentWrong;
    }

    this.setState({
      alertMsg: errorMessage,
      showAlert: true,
    })
  };

  createAccount(): boolean {
    this.setState({
      showAlert: false,
    })
    if (!this.state.email && !this.state.password) {
      this.setState({
        emailErrorMsg: configJSON.errorMsgPleaseEnterEmail,
        passwordErrorMsg: `${configJSON.errorMsgPleaseEnterPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`,
      });
      return false;
    }
    
    if (!this.isValidEmail(this.state.email)) {
      this.setState({emailErrorMsg: configJSON.errorMsgInvalidEmail});
    
      if (!this.state.password) {
        this.setState({
          passwordErrorMsg: 
          `${configJSON.errorMsgPleaseEnterPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`
        });

      } 
      else if (!this.passwordReg.test(this.state.password)) 
      {
        this.setState(
          {
          passwordErrorMsg: `${configJSON.starTxt}${configJSON.invalidTxt} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.commaTxt} ${configJSON.errorMsgInvalidPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`
        }
        );
      }else {
        return false
      }
    }

    if (!this.state.password) {
      this.setState({ passwordErrorMsg: `${configJSON.errorMsgPleaseEnterPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}` });
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.setState({
        passwordErrorMsg: `${configJSON.starTxt}${configJSON.invalidTxt} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.commaTxt} ${configJSON.errorMsgInvalidPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`,
      });
      return false;
      
    }

    if(!this.state.checkedPrivacyAndTerms){
      this.setState({
        alertMsg: configJSON.checkBoxAlertMsg,
        showAlert: true,
      });
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    storage.set("email",this.state.email)

    storage.set("typedEmail",''); storage.set("typedPassward",'')
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
