// Customizable Area Start
import React from "react";
import MyJobsApplicationController from "./MyJobsApplicationController";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import { Box, Grid, Typography, styled,Snackbar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { description, image, location, noResult} from "./assets";

// Customizable Area End

class MyJobsApplication extends MyJobsApplicationController {
  // Customizable Area Start
  render() {
    return (
      <>
        <CustomNavbar>
          <Navbar navigation={this.props.navigation}  id={""} NaviagationPage={""} />
        </CustomNavbar>
        <NavbarMobile>
          <ArrowBackIos data-test-id='btnBack' onClick={this.handleBack} style={webStyle.backIcon} />
          <NavbarTitle>Applied Jobs</NavbarTitle>
        </NavbarMobile> 
        <MainBox>
          <Navigation>
            <Typography data-test-id="navigateToHome" onClick={this.handleBack} style={webStyle.home}>Home</Typography>
            <ArrowForwardIos style={webStyle.forwardArrow} />
            <Typography style={webStyle.joblisting}>Applied Jobs</Typography>
          </Navigation>
          <Heading>Jobs Applied By You</Heading>
          <Box>
            {this.state.cardsDetails?.map((item,index) => {
              return (
                <>
                  <div style={webStyle.cards}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <CardTitle>
                        {item.attributes.job_title}
                      </CardTitle>
                      <PostedAtTypo>
                        <Typography style={{ ...webStyle.seconddiv, color: "#64748B" }}>Applied:</Typography>
                        <Typography style={webStyle.typeStyle}>{item.attributes.created_at}</Typography>
                      </PostedAtTypo>
                    </div>
                    <div style={webStyle.customdivStyle}>
                      <Typography style={webStyle.thirddiv}>{item.attributes.experience_required}</Typography>
                      <Typography style={webStyle.thirddiv}>{item.attributes.education_level}</Typography>
                    </div>
                    <div style={webStyle.ContainerStyle}>
                      <div>
                        <img src={image} />
                      </div>
                      <div>
                        <Typography style={webStyle.seconddiv}>{item.attributes.organization}</Typography>
                        <Typography style={{ ...webStyle.seconddiv, color: "#64748B" }} >{item.attributes.posted_by} . School admin</Typography>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "10px", marginTop: "24px" }}>
                      <div>
                        <img src={description} />
                      </div>
                      <div>
                        <Typography style={{ ...webStyle.seconddiv, color: "#475569",whiteSpace:'nowrap',marginTop:'-11px' }}>
                        <div dangerouslySetInnerHTML={{ __html: this.shortString(item.attributes.job_description) }} />
                        </Typography>
                      </div>
                    </div>
                    <Grid container style={webStyle.newdiv}>
                      <Grid item sm={6}>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <div style={{ width: "20.67px", height: "16.67px" }}>
                            <img src={location} style={{ width: "100%" }} />
                          </div>
                          <Typography style={webStyle.anotherdiv}>{item.attributes.city}, {item.attributes.state}, {item.attributes.country}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </>
              )
            })}
            {this.state.cardsDetails.length==0 && 
             <Box style={webStyle.noResultBox}>
             <Box>
               <img src={noResult} />
               <Typography style={webStyle.noResultText}>
                 No data found!
               </Typography>
              
             </Box>
           </Box>}
          </Box>
        </MainBox>
        <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
      </>
    );
  }
  // Customizable Area End
}

export default MyJobsApplication;
// Customizable Area Start
const CustomNavbar = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const NavbarMobile = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 571px)": {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#395169',
    padding: '15px 0 15px 20px',
    position: 'relative',
  },
});

const NavbarTitle = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
const MainBox = styled(Box)({
  background: "#F8F8F8",
  position: "relative",
  padding: "140px 375px 120px 375px",
  "@media only screen and (max-width: 1330px)": {
    paddingTop: 100,
    paddingLeft: 300,
    paddingRight: 300
  },
  "@media only screen and (max-width: 1235px)": {
    paddingTop: 100,
    paddingLeft: 230,
    paddingRight: 230
  },
  "@media only screen and (max-width: 1100px)": {
    paddingTop: 100,
    paddingLeft: 185,
    paddingRight: 185
  },
  "@media only screen and (max-width: 959px)": {
    paddingTop: 100,
    padding: 100,
  },
  "@media only screen and (max-width: 664px)": {
    padding: 37,
    paddingTop: 100
  },
  "@media only screen and (max-width: 571px)": {
    padding: 0,
    background: '#fff',
    paddingTop: 20
  }
})
const Navigation = styled(Box)({
  display: "flex",
  gap: "10px",
  position: "absolute" as "absolute",
  top: 32,
  left: 114,
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const Heading = styled(Typography)({
  color: '#1E293B',
  fontFamily: "Inter",
  fontSize: '30px',
  fontWeight: 700,
  lineHeight: '40px',
  marginBottom: 24,
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const PostedAtTypo = styled(Box)({
  display: "flex", gap: "5px",
  "@media only screen and (max-width: 375px)": {
    display: 'none'
  }
})
const CardTitle = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "26px",
  color: "0F172A",
  "@media only screen and (max-width: 414px)": {
    fontSize: 14
  }
})
const ViewDetail = styled(Grid)({
  display: "flex", justifyContent: "flex-end",
  "@media only screen and (max-width: 414px)": {
    display: 'none'
  }
})
const CustomSnackbar=styled(Snackbar)({
  boxShadow: "0px 6px 15px -3px #00000026",
  borderRadius:8,
  "&.MuiSnackbar-anchorOriginTopCenter":{
    top:158
  }
})
const webStyle = {
  backIcon: {
    color: '#fff'
  },
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4
  },
  cards: {
    padding: "32px",
    background: "#FFFFFF",
    borderRadius: "16px",
    marginBottom: "24px"
  },
  seconddiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
  },
  thirddiv: {
    backgroundColor: "#F8F8F8",
    padding: "2px 12px 2px 12px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",
    borderRadius: "2px"
  },
  fourthdiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A"
  },
  customdivStyle: {
    display: "flex",
    gap: "10px",
    marginTop: "12px",
    borderRadius: "2px"
  },
  ContainerStyle: {
    display: "flex",
    gap: "10px",
    marginTop: "20px"
  },
  typeStyle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#4788B7"
  },
  newdiv: {
    borderTop: "1px solid #F1F5F9",
    paddingTop: '20px',
    marginTop: "35px",
    display: "flex",
    justifyContent: "space-between"
  },
  anotherdiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A"
  },
  Saved: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left' as 'left',
  },
  popup: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    width: '332px',
    padding: '10px 10px 10px 10px',
    gap: '8px',
    borderFadius: '8px',
    boxShadow: '0px 6px 15px -3px #00000026',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  },
  noResultBox: {
    background: "#fff",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center" as "center",
    alignItem: "center" as "center",
    paddingTop: 88,
    paddingBottom: 128,
  },
  noResultText: {
    color: "0F172A",
    fontWeight: 600,
    marginTop: "16px",
    fontFamily: "Inter",
    fontSize: "20px",
    lineHeight: "28px",
  },
};
// Customizable Area End