// Customizable Area Start
import React from "react";
import {
  TextField,
  Grid,
  Typography,
  Button,
  styled,
  ThemeProvider,
  Checkbox,
  Slider,
  Box,
  IconButton,
  Chip,
  MenuItem,
  Input,
  Menu,
  Dialog
} from "@material-ui/core";
import {
  image,
  description,
  location,
  unchecked,
  checked,
  calendar,
  noResult,
  logo,
  state,
  profilePic,
  notFound
} from "./assets";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ClearIcon from "@material-ui/icons/Clear";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import MenuIcon from '@material-ui/icons/Menu'
import JobListingController, { CardsDetails } from "./JobListingController";
import Navbar from "../../../components/src/Navbar.web";
import CustomePagination from "../../..//components/src/CustomPagination.web";
import Footer from "../../../components/src/Footer.web";
import moment from "moment";
import "moment/locale/en-gb";
import { enGB } from "date-fns/locale";
import DrawerComponent from "../../../components/src/Drawer.web";

moment.locale("en-gb"); 

class JobListing extends JobListingController {
  static JobListing: JobListing;
 
  headingBox = () => {
    const { anchorEl , selectedSortItem , nameOfPageWhichShowingFull} = this.state    
    const open = Boolean(anchorEl)
    return (
      <div style={webStyle.heading}>
        <ShowingHeading>
          Showing{" "}
          <span style={{ fontWeight: 700 }}>
            {!this.state.searchedItemText && this.state.nameOfPageWhichShowingFull && <>` {nameOfPageWhichShowingFull} `</>}
            {this.state.searchedItemText && (
              <>`{this.state.searchedItemText}`</>
            )}
          </span>
        </ShowingHeading>
        {!this.state.isSearchEmpty ? (
          <>
          <Box style={{ display: "flex", gap: 5 , cursor:"pointer"}} data-test-id="handleSortDropDownClick" onClick={this.handleSortDropDownClick}  >
            <Typography style={webStyle.view}  >Sort by: {selectedSortItem ? selectedSortItem : "Relevance"}</Typography>
            <KeyboardArrowDownIcon style={{ marginTop: 1 }}/>
          </Box>
          <Dropdown
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleClose}
              data-test-id="dropdown-test-id"
            >
              <MenuItem style={webStyle.menuList} onClick={this.handleListingRelavance} id="Relevance">Relevance</MenuItem>
              <MenuItem style={webStyle.menuList} onClick={this.handleListingMostRecentData} id="MostRecent">Most Recent</MenuItem>
              <MenuItem style={webStyle.menuList} onClick={this.handleListingLeastRecentData} id='LeastRecent'>Least Recent</MenuItem>
            </Dropdown>
          </>
        ) : (
          <Typography style={webStyle.zerotext}>0 results</Typography>
        )}
      </div>
    );
  };

  noFoundTypo = () => {
    return (
      <Typography style={webStyle.subLine}>
        {!this.state.isFilterOptionEmpty ? (
          <>Try searching using another keyword</>
        ) : (
          <>
            It seems that none of the options match your search and filter
            criteria.
          </>
        )}
      </Typography>
    );
  };
  hasToken = () => {
    const {
      nameOfPageWhichShowingFull,
      logInMatchingProfileJobs,
      loginRecentJobs,
      loginInterestedJobs,
      isToken,
      isSearchedClicked,
    } = this.state;
    
    if (!isToken || isSearchedClicked) {
      return null;
    }

    const renderSection = (jobs: any[],
      name: string,
      handleView: (dataTestId: string) => void,
      dataTestId: string) => {

      if (jobs.length === 0) return null;

      const isFullView = nameOfPageWhichShowingFull === name;
      const displayStyle = isFullView || nameOfPageWhichShowingFull === "" ? "block" : "none";
      const headingStyle = name === "You may be interested in" ? { ...webStyle.heading, marginTop: "64px" } : webStyle.heading;

      return (
        <div style={{ display: displayStyle }}>
          <div style={headingStyle}>
            {!nameOfPageWhichShowingFull && <Typography style={webStyle.recently}>{name}</Typography>}
            {isFullView ? (
              this.headingBox()
            ) : (
              <div style={{ display: "flex" }}>
                <Typography style={webStyle.view} data-test-id={dataTestId} onClick={() => handleView(dataTestId)}>View all</Typography>
                <ArrowForwardIosIcon style={webStyle.forwardArrow} />
              </div>
            )}
          </div>
          {this.renderApiData(isFullView ? jobs : jobs.slice(0, 5))}
        </div>
      );
    };

    return (
      <>
        {renderSection(logInMatchingProfileJobs, "Matching your profile", this.handleViewForMatchingProfileJobs, "matchingProfile")}
        {renderSection(loginRecentJobs, "Recently added", this.handleViewForRecentlyAddedJobs, "recentlyadded")}
        {renderSection(loginInterestedJobs, "You may be interested in", this.handleViewInterestedJobs, "youmayintrestedin")}
      </>
    );
  };
  hasNoToken = () => {
    const { nameOfPageWhichShowingFull, noLoginRecentJobs,  noLoginInterestedJobs,isSearchedClicked, } = this.state;
    
    if ( isSearchedClicked) { return null; }

    const renderSection = (jobs: any[],
      name: string, handleView: (dataTestId: string) => void, dataTestId: string) => {
      if (jobs.length === 0) return null;
      const isFullView = nameOfPageWhichShowingFull === name;
      const displayStyle = isFullView || nameOfPageWhichShowingFull === "" ? "block" : "none";
      const headingStyle = name === "You may be interested in" ? { ...webStyle.heading, marginTop: "64px" } : webStyle.heading;

      return (
        <div style={{ display: displayStyle }}>
          <div style={headingStyle}>
            {!nameOfPageWhichShowingFull && <Typography style={webStyle.recently}>{name}</Typography>}
            {isFullView ? (this.headingBox()) : (
              <div style={{ display: "flex" }}>
                <Typography style={webStyle.view} data-test-id={dataTestId} onClick={() => handleView(dataTestId)}>View all</Typography>
                <ArrowForwardIosIcon style={webStyle.forwardArrow} />
              </div>
            )}
          </div> {this.renderApiData(isFullView ? jobs : jobs.slice(0, 5))} </div>
      );
    };

    return (
      <>
        {renderSection(noLoginRecentJobs, "Recently added", this.handleViewForRecentlyAddedJobs, "recentlyadded")}
        {renderSection(noLoginInterestedJobs, "You may be interested in", this.handleViewInterestedJobs, "youmayintrestedin")}
      </>
    );
  };
  hasTokenAdmin = () => {
    const {
      nameOfPageWhichShowingFull,
      isToken,
      isSearchedClicked,
      substituteTeacherDetails
    } = this.state;
    
    if (!isToken || isSearchedClicked) {
      return null;
    }

    const renderSection = (jobs: any[],
      name: string,
      handleView: (dataTestId: string , data:any[]) => void,
      dataTestId: string) => {

      if (jobs.length === 0) return null;

      const isFullView = nameOfPageWhichShowingFull === name;
      const displayStyle = isFullView || nameOfPageWhichShowingFull === "" ? "block" : "none";
      const headingStyle = webStyle.heading;

      return (
        <div style={{ display: displayStyle }}>
          <div style={headingStyle}>
            {!nameOfPageWhichShowingFull && <Typography style={webStyle.recently}>{name}</Typography>}
            {isFullView ? (
              this.headingBox()
            ) : (
              <div style={{ display: "flex" }}>
                <Typography style={webStyle.view} data-test-id={dataTestId} onClick={() => handleView(dataTestId , this.state.substitueTeacherCopy)}>View all</Typography>
                <ArrowForwardIosIcon style={webStyle.forwardArrow} />
              </div>
            )}
          </div>
          {this.renderTeacherListing(isFullView ? jobs : jobs.slice(0,5))}
        </div>
      );
    };
    
    return (
      <>
        {renderSection(substituteTeacherDetails, "Top Candidates", this.handleViewForTopCandidates, "topcandidates")}
      </>
    );
  };
  
  searchResult = () => {
    const {
      bachelorCheck, associateCheck, highSchoolCheck, certificateCheck,
      value, selectedState, selectedDateFrom, selectedDateTo,
      searchedResult, isSearchEmpty,
      searchedResultTeacher
    } = this.state;
    
    const selectedItems: string[] = [];
    if (bachelorCheck) selectedItems.push("Bachelor's degree");
    if (associateCheck) selectedItems.push("Associate's degree");
    if (highSchoolCheck) selectedItems.push('High school diploma or equivalent');
    if (certificateCheck) selectedItems.push('Certification in substitute teaching');
    if (value !== 0) selectedItems.push(`Exp - ${value} Yrs`);
    if (selectedState) selectedItems.push(selectedState);
    if (selectedDateFrom) selectedItems.push(`From - ${selectedDateFrom}`);
    if (selectedDateTo) selectedItems.push(`To - ${selectedDateTo}`);

    let resultContent;
    if (searchedResult?.length === 0 && searchedResultTeacher.length === 0 ) {
      resultContent = (
        <Box style={webStyle.noResultBox}>
          <Box>
            <img src={noResult} alt="No results found" />
            <Typography style={webStyle.noResultText}>No results found!</Typography>
            {this.noFoundTypo()}
          </Box>
        </Box>
      );
    } else {
      resultContent = isSearchEmpty ? (
        <Box style={webStyle.noResultBox}>
          <Box>
            <img src={noResult} alt="No results found" />
            <Typography style={webStyle.noResultText}>No results found</Typography>
            <Typography style={webStyle.subLine}>Try searching using another keyword</Typography>
          </Box>
        </Box>
      ) : (
        this.state.role == 1 ?
        this.renderTeacherListing(this.state.searchedResultTeacher):
        this.renderApiData(this.state.searchedResult)
      );
    }

    return (
      <>
        <>
          {this.headingBox()}
          {selectedItems.map((item: any, index) => (
            <ChipItem
              key={index}
              label={item}
              deleteIcon={<ClearIcon style={{ color: '#334155', height: "17px" }} />}
              style={{ marginRight: '8px', marginTop: '8px' }}
              data-test-id="handleChipDelete"
              onDelete={() => {
                this.handleChipDelete(item)
              }}
            />
          ))}
          {selectedItems.length > 0 && (
            <Chip
              label="Clear All"
              onClick={this.clearAll}
              data-test-id="clearAll"
              style={webStyle.clearall}
            />
          )}
          {resultContent}
        </>
      </>
    );
  };

  renderApiData = (data: CardsDetails[]) => {
    const { searchedResult } = this.state;
    const dataToRender = searchedResult?.length ? searchedResult : data;

    return (
      <>
        {dataToRender?.length > 0 &&
          dataToRender?.map((item) => {
            return (
              <div style={{position:'relative'}}>
              <div style={webStyle.cards}>
                <div style={{ display: "flex", justifyContent: "space-between", position:'relative' }}>
                  <Typography style={webStyle.firstdiv}>
                    {item?.attributes?.job_title}
                  </Typography>
                    
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Typography style={{ ...webStyle.seconddiv, color: "#64748B" }}>Posted:</Typography>
                    <Typography style={webStyle.typeStyle}>{item?.attributes?.created_at}</Typography>
                  </div>
                </div>
                <div style={webStyle.customdivStyle}>
                  <Typography style={webStyle.thirddiv}>{item?.attributes?.experience_required}</Typography>
                  <Typography style={webStyle.thirddiv}>{item?.attributes?.education_level}</Typography>
                </div>
                <div style={{...webStyle.ContainerStyle}}>
                  <div>
                    <img src={image} style={{width:"100%"}} />
                  </div>
                  <div>
                    <Typography style={webStyle.seconddiv}>{item?.attributes?.organization}</Typography>
                    <Typography style={{ ...webStyle.seconddiv, color: "#64748B" }} >{item?.attributes?.posted_by} . School admin</Typography>
                  </div>
                </div>
                <div style={{ display: "flex", gap: "10px", marginTop: "24px" }}>
                  <div>
                    <img src={description} />
                  </div>
                  <div>
                    <Typography style={{ ...webStyle.seconddiv, color: "#475569" }}>
                      <div dangerouslySetInnerHTML={{ __html: this.shortString(item?.attributes?.job_description) }} /></Typography>
                  </div>
                </div>
                <Grid container style={webStyle.newdiv}>
                  <Grid item sm={6}>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div style={{ width: "20.67px", height: "16.67px" }}>
                        <img src={location} style={{ width: "100%" }} />
                      </div>
                      <Typography style={webStyle.anotherdiv}>{item?.attributes?.city}, {item?.attributes?.state}, {item?.attributes?.country}</Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button style={webStyle.buttonStyle} data-test-id="viewDetailPage"
                      onClick={() => this.viewDetailPage(item?.attributes?.id)}>View details</Button>
                  </Grid>
                </Grid>
              </div>
              </div>
            )
          })}</>
    )
  }

  renderTeacherListing = (data:any[]) => {
    const { searchedResultTeacher } = this.state;
    const dataToRender = searchedResultTeacher?.length ? searchedResultTeacher : data;
    
    return (
      <>
        {dataToRender?.length > 0 &&
          dataToRender?.map((item:any) => {
            
            return (
              <div style={{position:'relative'}}>
              <div style={webStyle.cards}>
                  <div style={webStyle.ContainerStyle}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "start" }}>
                      <Box sx={webStyle.imageProfile}>
                        <img src={item?.attributes?.profile_image?.url || profilePic} 
                        data-test-id="profileImageTestiD"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = notFound;
                        }} alt={item?.attributes.profile_image.url} style={{ aspectRatio: "1", width: '100%', height: 'auto', borderRadius: "50%" }} />
                      </Box>
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                      <Typography style={webStyle.teacherTitle}>{item?.attributes?.first_name} {item?.attributes?.last_name}</Typography>
                      <Typography style={{ ...webStyle.seconddiv, color: "#64748B", marginTop: "10px" , fontSize:"16px" }} >{item?.attributes?.recent_work_experience?.position} Substitute Teacher</Typography>
                      <div style={{ marginTop: "17px" ,display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" , flexWrap:"wrap" , gap:"15px" }}>
                        <div style={{ display: "flex" ,justifyContent: "right",width: "100%"}}>
                          <div style={{display:"flex" , justifyContent:"center" , alignItems:"center"}}>
                            <WorkOutlineIcon style={{color:"#64748B",fontSize:"20px" , marginRight:"9px"}}/>
                            <Typography style={{ ...webStyle.smallText, marginRight: "25px" }}>{item?.attributes?.total_experience}</Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                <Box  style={{...webStyle.newdiv , gap:"15px" , flexWrap:"wrap"}}>
                  <Box >
                    <div style={{ display: "flex", gap: "10px", alignItems:"center" }}>
                      <div style={{ width: "20.67px", height: "16.67px" }}>
                        <img src={location} style={{ width: "100%" }} />
                      </div>
                      <Typography style={{...webStyle.anotherdiv}}>{item?.attributes?.city}, {item?.attributes?.state}, {item?.attributes?.country}</Typography>
                    </div>
                  </Box>
                  <Box  style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button style={webStyle.buttonStyle} data-test-id="viewDetailPageNew" onClick={()=>this.viewDetailPageTeacher(item?.attributes?.id , item.attributes.email ,`${item.attributes.first_name} ${item.attributes.last_name}`)}>View details</Button>
                  </Box>
                </Box>
              </div>
              </div>
            )
          })
        }
      </>
    )
  }

  listingTitle = ()=>{
    return this.state.fromViewTeacherDetail ?? 'Job listings';
  } 
  render() {
    const states = [...state.USA , ...state.Canada , ...state.Mexico];
    const allState = states.sort();
    const finalStates = this.state.showAll ? allState :allState.slice(0 , 4)
    const dateParts =
      this.state.selectedDateFrom !== ""
        ? this.state.selectedDateFrom.split("/")
        : moment
          .parseZone(new Date())
          .format("MM/DD/YYYY")
          .split("/");
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[0]) - 1;
    const days = parseInt(dateParts[1]);
    const date = new Date(year, month, days);
    const {role} = this.state
    return (
      <>
        <CustomNavBar>
          <Navbar
            navigation={this.props.navigation}
            id={""}
            NaviagationPage={"Home"}
          />
        </CustomNavBar>
        <CustomProfileHead> 
          <img src={logo} />
          <MenuIcon
          style={{ color: '#fff', position: 'absolute', right: '47px',cursor:'pointer' }}
          data-test-id="toggleDrawer"
          onClick={() => this.toggleDrawer(true)}
        />
        </CustomProfileHead>
        <DrawerComponent
          isOpen={this.state.isDrawerOpen}
          toggleDrawer={this.toggleDrawer} navigation={this.props.navigation}        />
        <MainBox>
          <NavigationCustom>
            {(this.state.showFilterBox || this.state.nameOfPageWhichShowingFull) &&(
              <div style={webStyle.navigation}>
                <Typography style={webStyle.home} onClick={this.navigateToBack}>Home</Typography>
                <ArrowForwardIosIcon style={webStyle.forwardArrow} />
                {this.state.searchedItemText.length == 0 ? (
                  <Typography style={webStyle.joblisting}>
                 {this.listingTitle()}
                  </Typography>
                ) : (
                  <Typography style={webStyle.joblisting}>
                    Search results
                  </Typography>
                )}
              </div>
            )}
          </NavigationCustom>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <SearchCustomBox>
                <SearchTypography style={webStyle.searchStyle}>
                  Search
                </SearchTypography>
                <div style={{ display: "flex" }}>
                  <SearchTextField
                    variant="outlined"
                    placeholder="ex. Assistant Teacher"
                    data-test-id="handleTextFieldChange"
                    onChange={this.handleTextFieldChange}
                    value={this.state.enteredText}
                    InputProps={{
                      endAdornment: this.state.enteredText && (
                        <IconButton
                          data-test-id="clearSearchText"
                          onClick={this.clearSearchText}
                          edge="end"
                          aria-label="clear search text"
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  />
                  <div
                    style={webStyle.searchBox}
                    data-test-id="handlesearch"
                    onClick={this.handleSearchButtonOnClick}
                  >
                    <SearchIcon />
                  </div>
                </div>
              </SearchCustomBox>
              {this.state.showFilterBox && (
                <div style={{ ...webStyle.gridStyle, marginTop: "16px" }}>
                  <Typography style={webStyle.filterStyle}>Filters</Typography>
                  <div style={webStyle.filterBoxes}>
                    <Typography style={webStyle.filterType}>
                      Education Level
                    </Typography>
                    <div style={{ display: "flex" }}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={this.state.bachelorCheck}
                          style={{ borderRadius: "10px" }}
                          data-test-id="handleBachelor"
                          onChange={this.handleBachelor}
                        />
                      </ThemeProvider>
                      <Typography style={webStyle.filters}>
                        Bachelor's degree
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={this.state.associateCheck}
                          style={{ borderRadius: "10px" }}
                          data-test-id="handleAssociat"
                          onChange={this.handleAssociat}
                        />
                      </ThemeProvider>
                      <Typography style={webStyle.filters}>
                        Associate's degree
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={this.state.highSchoolCheck}
                          style={{ borderRadius: "10px" }}
                          data-test-id="handlehighScool"
                          onChange={this.handlehighScool}
                        />
                      </ThemeProvider>
                      <Typography style={webStyle.filters}>
                        High school diploma or equivalent
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={this.state.certificateCheck}
                          style={{ borderRadius: "10px" }}
                          data-test-id="handlecertificate"
                          onChange={this.handlecertificate}
                        />
                      </ThemeProvider>
                      <Typography style={webStyle.filters}>
                        Certification in substitute teaching
                      </Typography>
                    </div>
                  </div>
                  <div style={webStyle.filterBoxes}>
                    <Typography style={webStyle.filterType}>
                      Experience
                    </Typography>
                    <SliderComponent
                      value={this.state.value}
                      min={0}
                      max={20}
                      step={1}
                      onChange={this.handleChange}
                      data-test-id="handleChange"
                      valueLabelDisplay="auto"
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography style={webStyle.yearexp}>0 Yrs</Typography>
                      <Typography style={webStyle.yearexp}>20 Yrs</Typography>
                    </div>
                  </div>
                  <div style={{...webStyle.filterBoxes ,  maxHeight:"228px", overflow :"scroll"}} >
                    <Typography style={webStyle.filterType}>State</Typography>
                    {finalStates.map((state, index) => (
                      <div key={index} style={{ display: "flex" }}>
                        <ThemeProvider theme={theme}>
                          <Checkbox
                            checked={this.state.selectedState === state ?true : false}
                            style={{ borderRadius: "10px" }}
                            data-test-id="handleStateSelect"
                            onChange={() => this.handleStateSelect(state)}
                          />
                        </ThemeProvider>
                        <Typography style={webStyle.filters}>
                          {state}
                        </Typography>
                      </div>
                    ))}
                    <Typography data-test-id ="handleToggle"  style={webStyle.viewMore} onClick={()=>this.handleToggle()} >
                      {this.state.showAll ? 'View Less' : 'View More'}
                      </Typography>
                  </div>
                  <div style={webStyle.filterBoxes}>
                    <Typography style={webStyle.filterType}>
                      Date Posted
                    </Typography>
                    <DateBox style={{ position: "relative" }}>
                      <Box style={{ position: "relative" }}>
                        <DateStyle
                          placeholder="From"
                          disabledStyle={this.state.ageError}
                          value={this.convertFormat()}
                          fullWidth
                          disableUnderline
                          name="companyName"
                          data-test-id="user-date-testID"
                        />
                        <CalendarImgBox>
                          <img
                            src={calendar}
                            id="user-date"
                            style={webStyle.calendarImg}
                            onClick={() => this.openDatePicker()}
                          />
                        </CalendarImgBox>
                        {this.state.datePickerIsOpen && (
                          <div
                            style={{
                              position: "absolute",
                              top: 60,
                              zIndex: 1000,
                            }}
                          >
                            <StyleDateBox
                              data-test-id="handleChanges"
                              onChange={this.handleChanges}
                              locale={enGB}
                              date={new Date()}
                              maxDate={new Date()}
                            />
                          </div>
                        )}
                      </Box>
                    </DateBox>

                    <DateBox style={{ position: "relative" }}>
                      <Box style={{ position: "relative" }}>
                        <DateStyle
                          fullWidth
                          placeholder="To"
                          value={this.convertFormatTo()}
                          disabledStyle={this.state.ageError}
                          data-test-id="user-date-testIDTo"
                          disableUnderline
                          name="companyName"
                        />
                        <CalendarImgBox>
                          <img
                            id="user-date"
                            src={calendar}
                            onClick={() => this.openDatePickerTo()}
                            style={webStyle.calendarImg}
                          />
                        </CalendarImgBox>
                        {this.state.datePickerToIsOpen && (
                          <div
                            style={{
                              position: "absolute",
                              top: 60,
                              zIndex: 1000,
                            }}
                          >
                            <StyleDateBox
                              locale={enGB}
                              onChange={this.handleChangesTo}
                              data-test-id="handleChanges"
                              date={date}
                              maxDate={new Date()} 
                            />
                          </div>
                        )}
                      </Box>
                    </DateBox>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>

              {!this.state.isToken &&
              this.hasNoToken()}
              {this.state.isToken && <>
            {role == 1  && this.hasTokenAdmin() }
            {role==2 && this.hasToken()}
              </>}
              {this.state.isSearchedClicked && this.searchResult()}
              { !this.state.isToken && !this.state.isSearchedClicked &&  role === 1 && (<>
                <div style={webStyle.heading}>
                  <Typography style={webStyle.recently}>Top Candidates</Typography>
                  <div style={{ display: "flex" }}>
                    <Typography style={webStyle.view}>View all</Typography>
                    <ArrowForwardIosIcon style={webStyle.forwardArrow} />
                  </div>
                </div>
                {this.renderTeacherListing(this.state.substituteTeacherDetails)}
              </>)}
              {
                (this?.state?.nameOfPageWhichShowingFull !== '' ||  this.state.total_count > 10 )&&
                <CustomePagination
                onchange={this.handlePaginationChange} 
                current_page={this.state.current_page}
                total_page={this.state.total_pages}
                total_count ={this.state.total_count}
                /> 
              }
            </Grid>
          </Grid>
        </MainBox>
        <Footer
          NaviagationPage={"Home"}
          navigation={this.props.navigation}
          id={""}
        />
      </>
    );
  }
}

export default JobListing;

const theme = createTheme({
  props: {
    MuiCheckbox: {
      icon: <img src={unchecked} />,
      checkedIcon: <img src={checked} />,
    },
  },
});
const CustomNavBar = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: "none",
  },
});
const CalendarImgBox = styled(Box)({
  marginRight: "10px",
  position: "absolute" as "absolute",
  top: 19,
  right: 68,
  "@media (max-width:1544px )": {
    right: 109,
  },
  "@media (max-width:1076px )": {
    right: 117,
  },
  "@media (max-width:1396px )": {
    right: 136,
  },
  "@media (max-width:375px )": {
    right: 19,
  },
});
const MainBox = styled(Box)({
  background: "#F8F8F8",
  position: "relative",
  padding: "140px 222px 120px 222px",
  "@media only screen and (max-width: 1330px)": {
    padding: 100,
  },
  "@media only screen and (max-width: 959px)": {
    paddingTop: 8,
    padding: 100,
  },
  "@media only screen and (max-width: 664px)": {
    padding: 37,
  },
  "@media only screen and (max-width: 414px)": {
    padding: 20,
  },
});
const ChipItem = styled(Chip)({
  backgroundColor: "#E7EFF2",
  color: "#475569",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  marginBottom: 24,
});
const ShowingHeading = styled(Typography)({
  color: "#475569",
  fontFamily: "Inter",
  fontSize: "24px",
  fontWeight: 400,
  lineHeight: "32px",
  "@media only screen and (max-width: 414px)": {
    fontSize: 14,
  },
});
const Dropdown = styled(Menu)({
  "& .MuiPopover-paper": {
    marginTop: "50px",
    border: '1px solid #E2E8F0',
    borderRadius: "8px"
  },
  "& .MuiPaper-elevation8": {
    boxShadow: "0px 4px 8px 0px #00000008",
    width: 180
  }
})
const CustomProfileHead = styled(Box)({
  display: "none",
  "@media only screen and (max-width: 571px)": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#395169",
    padding: 20,
    position: "relative",
    justifyContent: "center",
    paadingBottom: 37,
  },
});
const ViewDetail = styled(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  "@media only screen and (max-width: 414px)": {
    display: "none",
  },
});
const CardTitle = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "26px",
  color: "0F172A",
  "@media only screen and (max-width: 414px)": {
    fontSize: 14,
  },
});
const SearchTextField = styled(TextField)({
  borderTopLeftRadius: "4px",
  borderBottomLeftRadius: "4px",
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderRadius: "unset",
  },
});
const SearchCustomBox = styled(Box)({
  padding: "24px",
  background: "#FFFFFF",
  borderRadius: "16px",
  marginTop: "51px",
  "@media only screen and (max-width: 571px)": {
    marginTop: -57,
    padding: 0,
    borderRadius: 8,
  },
  "@media only screen and (max-width: 414px)": {
    padding: 0,
    borderRadius: 8,
    marginTop: -37,
  },
});
const SearchTypography = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#1E293B",
  marginBottom: "24px",
  "@media only screen and (max-width: 571px)": {
    display: "none",
  },
});
const NavigationCustom = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: "none",
  },
});
const SliderComponent = styled(Slider)({
  "& .MuiSlider-root": {
    color: "pink",
  },
  "& .MuiSlider-thumb": {
    width: "20px",
    height: "20px",
    backgroundColor: "#395169",
    marginTop: -8,
    marginLeft: -10,
  },
  "& .MuiSlider-rail": {
    height: "4px",
    backgroundColor: "#E4E4E4",
  },
  "& .MuiSlider-track": {
    height: "4px",
    backgroundColor: "#395169",
  },
  "& .MuiSlider-valueLabel": {
    color: "#395169",
    marginLeft: 9,
  },
});

const DateStyle = styled(Input)(({ disabledStyle }: any) => ({
  width: 313,
  border: disabledStyle
    ? "1px solid #F87171"
    : "1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
  borderRadius: "8px",
  height: "44px",
  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
  },
  padding: "10px 8px",
  marginTop: 10,
  "@media (max-width:1544px )": {
    width: 272,
  },
  "@media (max-width:1396px )": {
    width: 251,
  },
  "@media (max-width:1076px )": {
    width: 279,
  },
}));
const DateBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "374px",
  minWidth: "374px",
  "@media (max-width:660px )": {
    minWidth: "unset",
    maxWidth: "unset",
    flexWrap: "wrap",
    flexDirection: "column",
  },
});

const StyleDateBox = styled(Calendar)({
  "& .rdrDayNumber span": {
    fontWeight: 700,
  },
  "& .rdrMonth": {
    width: "unset",
  },
});
const PostedAtTypo = styled(Box)({
  display: "flex",
  gap: "5px",
  "@media only screen and (max-width: 375px)": {
    display: "none",
  },
});

const webStyle = {
  dot: {
    display: "inline-block",
    width: "4px",
    height: "4px",
    backgroundColor: "#64748B",
    borderRadius: "50%",
  },
  cards: {
    padding: "32px",
    background: "#FFFFFF",
    borderRadius: "16px",
    marginBottom: "42px",
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4,
  },
  calendarImg: {
    width: "24px",
    height: "24px",
  },
  searchBtn: {
    height: "100%",
    width: "44px",
  },
  firstdiv: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "26px",
    color: "0F172A",
  },
  searchStyle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1E293B",
    marginBottom: "24px",
  },
  seconddiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
  },
  smallText: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
  },
  teacherTitle:{
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing :"-0.5%"
  },
  anotherdiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A",
  },
  gridStyle: {
    padding: "24px",
    background: "#FFFFFF",
    borderRadius: "16px",
    marginTop: "51px",
  },
  thirddiv: {
    backgroundColor: "#F8F8F8",
    padding: "2px 12px 2px 12px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",
    borderRadius: "2px",
  },
  fourthdiv: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A",
  },
  typeStyle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#4788B7",
  },
  divStyle: {
    background: "#F8F8F8",
    padding: "140px 222px 120px 222px",
  },
  customdivStyle: {
    display: "flex",
    gap: "10px",
    marginTop: "12px",
    borderRadius: "2px",
  },
  buttonStyle: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#395169",
    border: "1px solid #395169",
    padding: "6px 16px 6px 16px",
    borderRadius: "8px",
    textTransform: "none" as "none",
  },
  ContainerStyle: {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    width:"100%"
  },
  newdiv: {
    borderTop: "1px solid #F1F5F9",
    paddingTop: "20px",
    marginTop: "35px",
    display: "flex",
    justifyContent: "space-between",
  },
  recently: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  view: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    cursor: "pointer"
  },
  heading: {
    display: "flex",
    marginBottom: "24px",
    justifyContent: "space-between",
    width: "100%"
  },
  searchBox: {
    background: "#395169",
    padding: "10px",
    borderRadius: "0px 8px 8px 0px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    cursor:"pointer"
  },
  navigation: {
    display: "flex",
    gap: "10px",
    position: "absolute" as "absolute",
    top: 32,
    left: 114,
  },
  filterType: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    marginBottom: "16px",
  },
  filterStyle: {
    fontWeight: 600,
    color: "#1E293B",
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "24px",
    marginBottom: "24px",
    paddingBottom: "24px",
    borderBottom: "1px solid #F1F5F9",
  },
  filters: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginTop: 7,
  },
  filterBoxes: {
    marginBottom: "24px",
    paddingBottom: "24px",
    borderBottom: "1px solid #F1F5F9",
  },
  viewMore: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    marginTop: "16px",
    paddingLeft: 37,
    cursor : "pointer"
  },
  yearexp: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  showingHeading: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
  },
  clearall: {
    marginRight: "8px",
    marginTop: -16,
    color: "#64748B",
    backgroundColor: "transparent",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  noResultBox: {
    background: "#fff",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center" as "center",
    alignItem: "center" as "center",
    paddingTop: 88,
    paddingBottom: 128,
  },
  noResultText: {
    color: "0F172A",
    fontWeight: 600,
    marginTop: "16px",
    fontFamily: "Inter",
    fontSize: "20px",
    lineHeight: "28px",
  },
  subLine: {
    color: "475569",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Inter",
    marginTop: "8px",
    maxWidth: "313px",
  },
  zerotext: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  menuList: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  inviteBtn:{
    color: '#395169',
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none" as "none",
    padding: "10px 16px",
    borderRadius: 8,
    cursor: 'pointer',
    marginBottom:'-41px',
    position:'absolute' as 'absolute',
    right: -6,
    bottom: 2,
  },
  appliedButton:{
    background : "#49D858",
    borderRadius : "2px",
    border:"none",
    outline:"none",
    width:"100%",
    maxWidth :"82px",
    height : "28px",
    color :"#FFFFFF"
  },
  imageProfile: {
    width:"127px",
    height:"127px",
    "@media (max-width:768px)": {
      width: "80px",
      height: "80px"
    } 
  }
};

// Customizable Area End
