// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");
import storage from '../../../framework/src/StorageProvider';

export interface Props {
  navigation: any;
  id: string;
}

interface AccountImage {
  type: string;
  filename: string;
  url: string;
  id: number;
}
export interface CardsDetails {
  id: string;
  attributes: JobAttributes;
  type: string;
}

interface JobAttributes {
  job_description: string;
  organization: string;
  job_title: string;
  street_address: string;
  state: string;
  country: string;
  key_responsibilities: string;
  experience_required: string;
  city: string;
  zip_code: string;
  post_created_at: string;
  id: string;
  about_organization: string;
  location: string;
  role_name: string;
  job_image: string;
  account_image: AccountImage;
  job_applied_at: string | null;
  posted_by: string;
  admin_email: string;
  created_at: string;
  qualifications: string;
  education_level: string;
  benefits: string;
  posted_at: string;
}


interface S {
  cardsDetails:CardsDetails[];
  isRemoved:boolean;
}

interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class SavedJobController extends BlockComponent<Props, S, SS> {
  getSavedlistId:string='';
  unsavejobID:string='';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      cardsDetails: [],
      isRemoved:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    const apiRequestCallId=message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));     
     if(this.getSavedlistId==apiRequestCallId){
      this.setState({cardsDetails:responseJson?.data})
     }
    }
  }

  handleBack = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobListing'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getSavedList()
  }
  getSavedList=async ()=>{
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };
    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    

    this.getSavedlistId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/applied_jobs`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  shortString=(value:string)=>{
      if (value.length <= 100) {
        return value;
      }return value.slice(0, 140) + '...';
  }
}

// Customizable Area End
