import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  MenuItem,styled, Menu
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { addpost, coin, logo } from "./assets";
import SurveyForm from "../../../components/src/SurveyForm.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getSurveySubmissionHandler = () => {
    return this.state.userRoleId === 2 
        ? this.handleTeacherSurveySubmission 
        : this.handleAdminSurveySubmission;
};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { activeOption, anchorEl } = this.state;
        const open = Boolean(anchorEl);
        
    return (
        <MainBox>
            <SurveyForm
                handleSurveyNotificationClose={this.handleSurveyNotificationClose}
                isSurveyNotificationOpen={this.state.isSurveyNotificationOpen}
                handleSurveySubmission={this.getSurveySubmissionHandler()}
                role = {this.state.userRoleId}
                handleTextFieldForTeacherChange={this.handleTextFieldForTeacherChange}
                handleTextFieldForAdminChange={this.handleTextFieldForAdminChange}
                isFormSubmited={this.state.isFormSubmited}
                successMessage={this.state.successMessage}
                ErrorMessage={this.state.ErrorMessage}
                formIsEmpty={this.state.formIsEmpty}
                adminFormData={this.state.admin_Survey}
                teacherFormData={this.state.substitute_Teacher_Survey}
                data-test-id="surveyFormTest"
                name={this.state.surveyTitle}
            />
        <img src={logo} />
        <HeadOption>
            <Typography
                style={{
                    ...webStyle.textstyle,
                    borderBottom: activeOption === "Home" ? "2px solid #C6DC68" : "none"
                }} data-test-id="handleHomClick"
                onClick={() => this.handleHomClick("Home")}
            >
                Home
            </Typography>
            {!this.state.token && <>
                <Typography
                    style={{
                        ...webStyle.textstyle,
                        borderBottom: activeOption === "FAQs" ? "2px solid #C6DC68" : "none"
                    }}
                    onClick={() => this.navigatePrivacyFaq("Faq")} data-test-id="handleOptionClick"
                >
                    FAQ’s
                </Typography>
                <Typography data-test-id="handleOptionClick"
                    style={{
                        ...webStyle.textstyle,
                        borderBottom: activeOption === "About" ? "2px solid #C6DC68" : "none"
                    }}
                    onClick={this.navigateAboutus}
                >
                    About us
                </Typography>

                <Button data-test-id="handleLogin" onClick={this.handleLogin} style={webStyle.loginBtn}>Login</Button>
                <Button data-test-id="handleSignup" onClick={this.handleSignup} style={webStyle.signBtn}>Sign up</Button>
            </>}
            {this.state.userRoleId == 2 && <>
                <Typography
                    style={{
                        ...webStyle.textstyle,
                        borderBottom: activeOption === "savedjob" ? "2px solid #C6DC68" : "none"
                    }} data-test-id="handleSavedJob"
                    onClick={() => this.handleSavedJob("savedjob")}
                >
                    Saved Jobs
                </Typography>

                <Typography data-test-id="handleChatClick"
                    style={{
                        ...webStyle.textstyle,
                        borderBottom: activeOption === "Chat" ? "2px solid #C6DC68" : "none"
                    }}
                    onClick={() => this.handleChatClick("Chat")}
                >
                    Chat
                </Typography>
                <ProfileImg data-test-id="handleProfileClick" onClick={this.handleProfileClick}>
                    <Box style={{display:this.state.profilePic==""||null ? 'none':''}}>
                        <img src={this.state.profilePic} style={{height:32,width:32,borderRadius:'50%'}}/>
                    </Box>
                    <Box style={{ display: "flex", marginTop: 4 }}>
                        <Typography style={webStyle.name}>{this.state.firstName} {this.state.lastName}.</Typography>
                        <KeyboardArrowDownIcon />
                    </Box>
                </ProfileImg>
                <Dropdown
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    data-test-id="handleClose"
                >
                    <MenuItem style={webStyle.menuList} data-test-id="handleProfileSelect" onClick={this.handleProfileSelect}>Profile</MenuItem>
                    <MenuItem style={webStyle.menuList} data-test-id="handleAppliedJobsApp" onClick={this.handleAppliedJobsApp}>My job applications</MenuItem>
                    <MenuItem style={webStyle.menuList} data-test-id="navigateToSetting" onClick={this.navigateToSetting}>Settings</MenuItem>
                    <MenuItem style={{ ...webStyle.menuList, color: "#DC2626" }} data-test-id="handleLogout" onClick={this.handleLogout}>Logout</MenuItem>
                </Dropdown>
                
            </> }
            {this.state.userRoleId==1 && 
                <>
                    <Typography
                        style={{
                            ...webStyle.textstyle,
                            borderBottom: activeOption === "SavedTeachers" ? "2px solid #C6DC68" : "none"
                        }} data-test-id="handleTeamClick"
                        onClick={() => this.handleTeamClick("SavedTeachers")}
                    >
                        Saved Teachers
                    </Typography>
                    <Typography
                        style={{
                            ...webStyle.textstyle,
                            borderBottom: activeOption === "Chat" ? "2px solid #C6DC68" : "none"
                        }} data-test-id="handleChatClick"
                        onClick={() => this.handleChatClick("Chat")}
                    >
                        Chat
                    </Typography>
                    <ProfileImgAdmin onClick={this.handleProfileClick}>
                        <Box style={{display:this.state.profilePic==""||null ? 'none':''}}>
                            <img src={this.state.profilePic} style={{height:32,width:32,borderRadius:'50%'}}/>
                        </Box>
                        <Box style={{ display: "flex", marginTop: 4 }}>
                            <Typography style={webStyle.name}>{this.state.firstName} {this.state.lastName}.</Typography>
                            <KeyboardArrowDownIcon />
                        </Box>
                    </ProfileImgAdmin>
                    <NewJob>
                        <img src={addpost} data-test-id="handleAddPost" onClick={this.handleAddPost}/>
                    </NewJob>
                    <Dropdown
                        anchorEl={anchorEl}
                        open={open}
                        onClose={this.handleClose}
                    >
                        <MenuItem style={webStyle.menuList} onClick={this.handleProfileSelect}>Profile</MenuItem>
                        <MenuItem style={webStyle.menuList} onClick={this.navigateToSetting}>Settings</MenuItem>
                        <MenuItem style={{ ...webStyle.menuList, color: "#DC2626" }} onClick={this.handleLogout}>Logout</MenuItem>
                    </Dropdown>
                    <Box style={{display:'flex', cursor:'pointer'}} data-test-id="viewTrancastions" onClick={this.viewTrancastions}>
                        <Typography style={webStyle.textstyle}>{this.state.totalCredits}</Typography>
                        <img src={coin} style={{width:'25px',height:'25px',marginTop:'6px'}}/>
                    </Box>
                </>}
        </HeadOption>
    </MainBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const MainBox = styled(Box)({
    padding: "20px 120px",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 2px 8px 0px #00000014",
    "@media only screen and (max-width: 890px)": {
        padding: "20px 58px"
    },
    "@media only screen and (max-width: 774px)": {
        padding: "20px 35px"
    },
})
const Dropdown = styled(Menu)({
    "& .MuiPopover-paper": {
        marginTop: "50px",
        border: '1px solid #E2E8F0',
        borderRadius: "8px"
    },
    "& .MuiPaper-elevation8": {
        boxShadow: "0px 4px 8px 0px #00000008",
        width:180
    }
})
const HeadOption = styled(Box)({
    display: "flex",
    gap: "40px",
    alignItems: "center",
    "@media only screen and (max-width: 978px)": {
        gap: 12
    },
    "@media only screen and (max-width: 712px)": {
        gap: 4
    },
    "@media only screen and (max-width: 635px)": {
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
})
const ProfileImg = styled(Box)({
    display: "flex",
    gap: 12,
    paddingLeft: "40px",
    borderLeft: "1px solid #E2E8F0",
    cursor:'pointer',
    "@media only screen and (max-width: 712px)": {
        paddingLeft: 10,
    },
})
const ProfileImgAdmin = styled(Box)({
    paddingRight: "40px",
    display: "flex",
    gap: 12,
    borderRight: "1px solid #E2E8F0",
    "@media only screen and (max-width: 712px)": {
        paddingLeft: 10,
    },
})
const NewJob=styled(Box)({
    display:'flex',
    justifyContent:'space-between',
    cursor:'pointer'
})
const webStyle = {
    option: {
        display: "flex",
        gap: "40px",
        alignItems: "center"
    },
    textstyle: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        padding: "8px 6px",
        cursor: "pointer"
    },
    loginBtn: {
        color: "#395169",
        border: "1px solid #395169",
        borderRadius: "8px",
        padding: "10px 32px",
        fontFamily: "Inter",
        textTransform: "none" as "none",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    signBtn: {
        color: "#FFFFFF",
        border: "1px solid #395169",
        padding: "10px 32px",
        borderRadius: "8px",
        background: "#395169",
        fontFamily: "Inter",
        fontSize: "14px",
        textTransform: "none" as "none",
        fontWeight: 700,
        lineHeight: "22px",
    },
    profile: {
        display: "flex",
        gap: 12,
        paddingLeft: "40px",
        borderLeft: "1px solid #E2E8F0"
    },
    name: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
    },
    menuList: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    }
}
// Customizable Area End
