import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
interface Time {
  hour: number;
  minute: number;
  period: 'AM' | 'PM';
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: [];
  token: string;
  name: string;
  description: string;
  price: string;
  currency: string;
  category_id: string;
  image: string | undefined;
  uploadedImages: [];
  AllCategory: [];
  id: string;
  refresh: boolean;
  file: string;
  profileImageData: { data: string | null | undefined, content_type:string, filename:string };
  selectedCategory: ValueType<OptionTypeBase, false>;
  allCategories: { value: string, label: string}[];
  educationList:string[];
  experienceList:number[];
  currentStep:number;
  countryList:string[];
  jobTitle:string;
  organisation:string;
  education:string;
  experience:number|string;
  jobdescription:string;
  state:string;
  city:string;
  zipcode:string;
  country:string;
  aboutOrg:string;
  street:string;
  savedAddress:boolean;
  openPreview:boolean;
  schoolname:string;
  jobTitleError: string;
  organisationError: string;
  educationError: string;
  experienceError: string;
  jobDesciptionError:string;
  streetError: string;
  cityError: string;
  stateError: string;
  zipcodeError: string;
  countryError: string;
  aboutOrgError: string;
  firstname:string;
  lastname:string;
  creditError:boolean;
  editAvailbility:boolean;
  workingDaysArray:string[];
  selectedWorkingDays:string[];
  selectedWorkingDayIndices: number[];
  workingDays: {
    [key: string]: boolean;
    'Monday': boolean;
    'Tuesday': boolean;
    'Wednesday': boolean;
    'Thursday': boolean;
    'Friday': boolean;
    'Saturday': boolean;
    'Sunday': boolean;
  };
  workTimes: { [key: string]: { start: Time; end: Time } };
  startClicked: boolean;
  endClicked: boolean;
  activeDay: string | null;
  anchorElDocStart: HTMLElement | null;
  calendarValueDocStart: Date | null;
  textFieldValueDocStart: string;
  anchorElDocEnd: HTMLElement | null;
  calendarValueDocEnd: Date | null;
  textFieldValueDocEnd: string;
  daysOfWeek:{label: string; day: string;}[];
  workingdayError:string;
  startTimeError:string;
  endTimeError:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";;
  updatePostApiCallId: string = "";;
  addePostId:string='';
  getProfileDataId:string='';
  getProfileAddressId:string='';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: {data:null, content_type:"", filename:""},
      selectedCategory: {},
      allCategories: [],
      educationList:["Bachelor's degree","Associate's degree","High school diploma or equivalent","Certification in substitute teaching"],
      experienceList:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
      currentStep:1,
       countryList:['USA','Canada','Mexico'],
       jobTitle:'',
       organisation:'',
       education:'Select option',
       experience:'Select option',
       jobdescription:'',
       state:'',
       city:'',
       zipcode:'',
       country:'Select option',
       aboutOrg:'',
       street:'',
       savedAddress:false,
       openPreview:false,
       schoolname:'Riverdale Elementary',
       jobTitleError: '',
       organisationError: '',
       educationError: '',
       experienceError: '',
       jobDesciptionError:'',
       streetError: '',
       cityError: '',
       stateError: '',
       zipcodeError: '',
       countryError: '',
       aboutOrgError: '',
       firstname:'',
       lastname:'',
       creditError:false,
       editAvailbility:false,
       workingDaysArray: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      selectedWorkingDays:[],
      selectedWorkingDayIndices: [],
      workingDays: {
        'Monday': true,
        'Tuesday': true,
        'Wednesday': true,
        'Thursday': true,
        'Friday': true,
        'Saturday': true,
        'Sunday': true,
      },
      workTimes: {},
      startClicked: false,
      endClicked: false,
      activeDay:null,
      anchorElDocStart: null,
      calendarValueDocStart: null,
      textFieldValueDocStart: '',
      anchorElDocEnd: null,
      calendarValueDocEnd: null,
      textFieldValueDocEnd: '',
      daysOfWeek : [
        { label: 'M', day: 'Monday' },
        { label: 'T', day: 'Tuesday' },
        { label: 'W', day: 'Wednesday' },
        { label: 'T', day: 'Thursday' },
        { label: 'F', day: 'Friday' },
        { label: 'S', day: 'Saturday' },
        { label: 'S', day: 'Sunday' },
      ],
      workingdayError:'',
      startTimeError:'',
      endTimeError:'',
    };
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {
        this.getAllCategory();
      });

    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const item = message.getData(getName(MessageEnum.PostDetailDataMessage));
      if (item) {
        this.setState({
          name: item.attributes.name,
          description: item.attributes.description,
          price: item.attributes.price?.toString(),
          currency: "$",
          category_id: item.attributes.category_id,
          id: item.attributes.id,
          profileImageData: {
            data: item.attributes.product_image,
            content_type: "image/jpeg",
            filename: "image.jpeg"
          },
          image: ""
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        if (apiRequestCallId === this.apiPostItemCallId) {
          this.setState({ PostData: responseJson.data, refresh: false });
        } else if (apiRequestCallId === this.addpostApiCallId) {
          this.setState({ refresh: true });
          alert(configJSON.PostCreated)
          this.props.navigation.goBack();
        } else if (apiRequestCallId === this.apiGetCategoryCallID) {
          var allCategories: {value: string, label: string}[] = [];
          let categories: string[] = [];

          const posts = responseJson.data;

          posts.forEach((item: {attributes: { name:string, id:string}}) => {
            if (categories.indexOf(item.attributes.name) === -1) {
              var category = {
                value: item.attributes.id,
                label: item.attributes.name
              };
              allCategories.push(category);
              categories.push(item.attributes.name);
            }
          });

          this.setState({
            AllCategory: responseJson.data,
            allCategories: allCategories
          });
        } else if (apiRequestCallId === this.updatePostApiCallId) {
          this.setState({ refresh: true });
          alert(configJSON.PostUpdated)
          this.props.navigation.goBack();
        } else if (apiRequestCallId === this.DeleteApiCallId) {
        }
      } else if (apiRequestCallId === this.DeleteApiCallId) {
      } else if (errorReponse) {
        this.setState({ refresh: false });
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.addPost(responseJson)
       this.getApiReceive(responseJson)
    }
  }

  addPost=(responseJson:any)=>{
    if(this.addePostId){
      if(responseJson.error){
        this.setState({creditError:true})
      }
      else{
        this.navigateToHome()
      }
    }
  }

  getApiReceive=(responseJson: { data: { attributes: { state: string; city: string; country: string; street_address: string; zip_code: string; first_name: string; last_name: string; }; }; })=>{
    if(this.getProfileAddressId){
      if(this.state.savedAddress){
        this.setState({
          state:responseJson.data.attributes.state, 
          city:responseJson.data.attributes.city, 
          country:responseJson.data.attributes.country, 
          street:responseJson.data.attributes.street_address,
          zipcode:responseJson.data.attributes.zip_code,
          
      })
      } else if(!this.state.savedAddress) {
        this.setState({
          state:'', 
          city:'', 
          country:'Select option', 
          street:'',
          zipcode:'',
        })
      }
    } 
    if(this.getProfileDataId){
      this.setState({firstname:responseJson.data.attributes.first_name,
        lastname:responseJson.data.attributes.last_name})
    }
  }

  createPostCreation() {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
    } else {
      this.AddPostCreation();
    }
  }

  goToItemDetails(item:{attributes: { name:string, id:string}} | null, isEdit: boolean) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit ? "PostCreation" : "PostDetails"
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  editNavigation = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, true);
  };

  navigateToDetails = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, false);
  };

  AddPostCreation(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const attrs = {
          name: this.state.name,
          description: this.state.description,
          body: this.state.description,
          price: this.state.price,
          currency: "$",
          category_id: this.state.category_id,
          sub_category_id: 1,
          image: this.state.profileImageData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllCategory() {
  }

  updateCreatePostData(recordId: string) {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token
      };
      const attrs = {
            name: this.state.name,
            description: this.state.description,
            body: this.state.description,
            sub_category_id: 1,
            price: this.state.price,
            currency: "$",
            category_id: this.state.category_id,
            image: this.state.profileImageData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePostApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGetUrl + "/" + `${recordId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  deleteRecord(recordId: string) {
    Alert.alert(
      configJSON.Warning,
      configJSON.MessageForDelete,
      [
        { text: "No", onPress: () => {}, style: "cancel" },
        {
          text: "Yes",
          onPress: () => {
            this.setState({ refresh: true });
            this.delete(recordId);
          }
        }
      ],
      { cancelable: false }
    );
  }

  delete(recordId: string) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${recordId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  txtInputProductNameProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ name: text });
      this.txtInputProductNameProps.value = text;
    }
  };

  txtInputProductDiscripationProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ description: text });
      this.txtInputProductDiscripationProps.value = text;
    }
  };

  txtInputProductPriceProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ price: text });
      this.txtInputProductPriceProps.value = text;
    }
  };

  chooseImage = () => {
    this.showAlert("Error", "Image Picker Not Implemented");
  };

  handleCatChangeChange = (selectedOption: ValueType<OptionTypeBase, false>) => {
    this.setState({
      selectedCategory: selectedOption,
      category_id: selectedOption?.value
    });
  };

  navigateToHome=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobListing' 
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  
  handleCancleTwo=()=>{
    this.setState({currentStep:2})
  }
  onValueChange = (name: string, value: string | unknown) => {
    let valuenumber = value as string;
    let cleanedValue = valuenumber;
    let errorMessage = '';

    if (name === 'jobTitle' || name === 'city' || name === 'state' || name === 'organisation') {
      cleanedValue = valuenumber.replace(/\d/g, '');
      if (value !== cleanedValue) {
        errorMessage = 'Only characters are allowed.';
      }
    } 

    this.setState({ ...this.state, [name]: cleanedValue, [`${name}Error`]: errorMessage });
  };

  validateFields = () => {
    const { jobTitle, organisation, education, experience, jobdescription } = this.state;
    let isValid = true;

    if (!jobTitle) {
      this.setState({ jobTitleError: 'This field is required.' });
      isValid = false;
    }

    if (!organisation) {
      this.setState({ organisationError: 'This field is required.' });
      isValid = false;
    }

    if (education == 'Select option') {
      this.setState({ educationError: 'This field is required.' });
      isValid = false;
    }

    if (experience == 'Select option') {
      this.setState({ experienceError: 'This field is required.' });
      isValid = false;
    }

    if (!jobdescription) {
      this.setState({ jobDesciptionError: 'This field is required.' });
      isValid = false;
    }

    return isValid;
  };

  stepOneContinue = () => {
    if(this.state.selectedWorkingDays.length==0){
      this.setState({workingdayError:'Select the position working day'})
    }else this.setState({workingdayError:''})
    if(this.state.textFieldValueDocStart==''){
      this.setState({startTimeError:'Select start date'})
    }else this.setState({startTimeError:''})
    if(this.state.textFieldValueDocEnd==''){
      this.setState({endTimeError:'Select end date'})
    } else this.setState({endTimeError:''})
    if(this.state.selectedWorkingDays.length>0 && this.state.textFieldValueDocStart && this.state.textFieldValueDocEnd)
      this.setState({ currentStep: 2 });
    window.scrollTo(0,0)
  };
  backStepOne=()=>{
    this.setState({ currentStep: 1 });
  }
  stepTwoContinue=()=>{
    if (this.validateFields()) {
    this.setState({currentStep:3})
    window.scrollTo(0,0)
    }
  }
  savedAddressClick=async ()=>{
    this.setState({ savedAddress: !this.state.savedAddress })
    const token = await storage.get("loginToken")
    const userId=await storage.get('loginId')
    const header = {
      token: token,
    };

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileAddressId = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/custom_forms/${userId}/show_profile`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }
  handlePreview=async ()=>{
    if(this.validateFieldsTwo()){
    this.setState({openPreview:true})
    this.getUserProfile()
    }
  }
  closePreview=()=>{
    this.setState({openPreview:false})
  }
  validateFieldsTwo = () => {
    const { street, city, country, zipcode, state, aboutOrg } = this.state;
    let isValid = true;

    if (!street) {
      this.setState({ streetError: 'This field is required.' });
      isValid = false;
    }

    if (!aboutOrg) {
      this.setState({ aboutOrgError: 'This field is required.' });
      isValid = false;
    }

    if (country == 'Select option') {
      this.setState({ countryError: 'This field is required.' });
      isValid = false;
    }
    if (!state) {
      this.setState({ stateError: 'This field is required.' });
      isValid = false;
    }
    if (!city) {
      this.setState({ cityError: 'This field is required.' });
      isValid = false;
    }
    if (!zipcode) {
      this.setState({ zipcodeError: 'This field is required.' });
      isValid = false;
    }

    return isValid;
  };
  publishBtn=async ()=>{
    const dayMapping :any= {
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6,
      'Sunday': 7,
    };
    
    if(this.validateFieldsTwo()){
      const header = {
        token: await storage.get('loginToken')
      };
      const formData = new FormData();
      formData.append("job_creation[job_title]", this.state.jobTitle);
      formData.append("job_creation[organization]", this.state.organisation);
      formData.append("job_creation[education_level]", this.state.education);
      formData.append("job_creation[experience_required]", this.state.experience.toString());
      formData.append("job_creation[job_description]", this.state.jobdescription);
      formData.append("job_creation[street_address]", this.state.street);
      formData.append("job_creation[city]", this.state.city);
      formData.append("job_creation[state]", this.state.state);
      formData.append("job_creation[zip_code]", this.state.zipcode+'');
      formData.append("job_creation[country]", this.state.country);
      formData.append("job_creation[about_organization]", this.state.aboutOrg);

      this.state.selectedWorkingDays.forEach((dayName) => {
        const dayIndex = dayMapping[dayName];
        const startTime = this.state.workTimes[dayIndex]?.start || { hour: 9, minute: 0, period: 'AM' };
        const endTime = this.state.workTimes[dayIndex]?.end || { hour: 5, minute: 0, period: 'PM' };
        const isFullDay = this.state.selectedWorkingDayIndices.includes(dayIndex);
    
        if (dayIndex) {
          formData.append(`job_creation[working_days_attributes][][working_day_id]`, dayIndex.toString());
          formData.append(`job_creation[working_days_attributes][][start_time]`, `${startTime.hour}:${startTime.minute} ${startTime.period}`);
          formData.append(`job_creation[working_days_attributes][][end_time]`, `${endTime.hour}:${endTime.minute} ${endTime.period}`);
          formData.append(`job_creation[working_days_attributes][][full_day]`, isFullDay ? 'true' : 'false');
        }
      });
      formData.append("job_creation[about_organization]", this.state.aboutOrg);
      formData.append("job_creation[start_date]", this.state.textFieldValueDocStart);
      formData.append("job_creation[end_date]", this.state.textFieldValueDocEnd);
      formData.append("job_creation[account_id]", await storage.get("loginId"));
      formData.append("job_creation_params[use_my_save_address]", this.state.savedAddress.toString());
      

      const setIntroMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addePostId = setIntroMsg.messageId;

      setIntroMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_joblisting/job_creations`
      );

      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);

    }
  }
  getUserProfile=async ()=>{
    const token = await storage.get("loginToken")
    const userId=await storage.get('loginId')
    const header = {
      token: token,
    };

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataId = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/custom_forms/${userId}/show_profile`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }

  workingDaysClick = (providerType: string, index: number) => {
    this.setState((prevState) => {
      const isCurrentlySelected = prevState.selectedWorkingDays.includes(providerType);
      const newSelectedWorkingDays = isCurrentlySelected
        ? prevState.selectedWorkingDays.filter(day => day !== providerType)
        : [...prevState.selectedWorkingDays, providerType];
  
      const newSelectedWorkingDayIndices = isCurrentlySelected
        ? prevState.selectedWorkingDayIndices.filter(i => i !== index)
        : [...prevState.selectedWorkingDayIndices, index];
  
      return {
        selectedWorkingDays: newSelectedWorkingDays,
        selectedWorkingDayIndices: newSelectedWorkingDayIndices,
      };
    });
  };
  

  saveAvailbility = () => {
    this.setState({ editAvailbility: false })
  }


  dayToggle = (day: string) => {
    this.setState((prevState) => {
      const newWorkingDays = {
        ...prevState.workingDays,
        [day]: !prevState.workingDays[day],
      };

      return {
        workingDays: newWorkingDays,
      };
    });
  };

  editAvailability = () => {
    this.setState({ editAvailbility: true })
  }

  handleTimeChange = (day: string, type: 'start' | 'end', value: Time) => {
    this.setState((prevState) => ({
      workTimes: {
        ...prevState.workTimes,
        [day]: {
          ...prevState.workTimes[day],
          [type]: value
        }
      }
    }));
  };

  clickTime = (day: string) => {
    this.setState({ startClicked: true, activeDay: day });
  };

  clickEndTime = (day: string) => {
    this.setState({ endClicked: true, activeDay: day });
  }

  closeTimeDialog = () => {
    this.setState({ startClicked: false, endClicked: false, activeDay: null });
  };

  saveStartTime = (day: string, startTime: { hour: number; minute: number; period: 'AM' | 'PM' }) => {
    this.closeTimeDialog()
  };
  saveEndTime = (day: string, endTime: { hour: number; minute: number; period: 'AM' | 'PM' }, startTime: { hour: number; minute: number; period: 'AM' | 'PM' }) => {
    this.closeTimeDialog()
  };

  handleStartCalender = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElDocStart: event.currentTarget,
    });
  };
  
  handleCloseDocanchorElDocStart = () => {
    this.setState({
      anchorElDocStart: null,
      anchorElDocEnd:null
    });
  };
  handleDateChangesdoc = (stateKey: string, textFieldKey: string, anchorKey: string) => (date: Date) => {
    const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
    this.setState({
      [stateKey]: date,
      [textFieldKey]: formattedDate,
      [anchorKey]: null,
    } as any);
  };
  handleendCalender = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElDocEnd: event.currentTarget,
    });
  };

  navigateAddCredit=()=>{
    const dayMapping :any= {
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6,
      'Sunday': 7,
    };
    storage.set('jobTitle',this.state.jobTitle); storage.set('organisation',this.state.organisation); storage.set('education',this.state.education); storage.set('experience',this.state.experience)
    storage.set('jobdescription',this.state.jobdescription); storage.set('street',this.state.street); storage.set('city',this.state.city); storage.set('country',this.state.country); storage.set('state',this.state.state)
    storage.set('zipcode',this.state.zipcode); storage.set('aboutOrg',this.state.aboutOrg); storage.set('firstname',this.state.firstname); storage.set('lastname',this.state.lastname);
    storage.set('startDate',this.state.textFieldValueDocStart); storage.set('endDate', this.state.textFieldValueDocEnd); storage.set('savedAddress',this.state.savedAddress)

    const workingDays = this.state.selectedWorkingDays.map(dayName => {
      const dayIndex = dayMapping[dayName];
      const startTime = this.state.workTimes[dayIndex]?.start || { hour: 9, minute: 0, period: 'AM' };
      const endTime = this.state.workTimes[dayIndex]?.end || { hour: 5, minute: 0, period: 'PM' };
      const isFullDay = this.state.selectedWorkingDayIndices.includes(dayIndex);
  
      return {
        working_day_id: dayIndex,
        start_time: `${startTime.hour}:${startTime.minute} ${startTime.period}`,
        end_time: `${endTime.hour}:${endTime.minute} ${endTime.period}`,
        full_day: isFullDay,
      };
    });
    storage.set('workingDays', JSON.stringify(workingDays));

    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Payments' 
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  closePopup=()=>{
    this.setState({creditError:false})
  }
  // Customizable Area End
}
